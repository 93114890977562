import React from 'react';
import PropTypes from 'prop-types';

const CheckboxOn = ({ fill }) => (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>Group 3</title>
        <defs>
            <path d='M5.18184316,9.35661838 C4.91148018,9.08625541 4.4731352,9.08625541 4.20277223,9.35661838 C3.93240926,9.62698135 3.93240926,10.0653263 4.20277223,10.3356893 L7.66431069,13.7972278 C7.93467366,14.0675907 8.37301865,14.0675907 8.64338162,13.7972278 L16.2587662,6.18184316 C16.5291292,5.91148018 16.5291292,5.4731352 16.2587662,5.20277223 C15.9884033,4.93240926 15.5500583,4.93240926 15.2796953,5.20277223 L8.15384615,12.3286214 L5.18184316,9.35661838 Z' id='path-1' />
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search-Result-Copy-2' transform='translate(-266.000000, -499.000000)'>
                <g id='Group-3' transform='translate(266.000000, 499.000000)'>
                    <rect id='Rectangle-Copy-4' stroke='#070707' strokeWidth='3' fill='#ED1C24' x='1.5' y='1.5' width='17' height='17' />
                    <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Shape-Copy' fill='#2D2D2D' fillRule='nonzero' xlinkHref='#path-1' />
                </g>
            </g>
        </g>
    </svg>
);

CheckboxOn.propTypes = {
    fill: PropTypes.string
};

export default CheckboxOn;
