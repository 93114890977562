import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import { Subtitle } from './Typography';
import { SearchContext } from '../pages/Search';
import IconButton from './IconButton';

const Tags = props => {
    const { setFilters } = useContext(SearchContext);
    const { key, value } = props.filter;

    const pubRangeFilter = key === 'publication_range';

    const text = pubRangeFilter ? Object.values(value).join(' - ') : value.text;

    const removeTag = () => {
        setFilters(filters => filters.filter(oldFilter => oldFilter.key !== key));
    };
    return (
        <Box
            className='Tags'
            type='gray-filled'
            padded={false}
        >
            <Subtitle capitalize>{`${pubRangeFilter ? key.replace('_', ' ') : key}: ${text}`}</Subtitle>
            <IconButton className='Tags__Close' name='close' size='small' onClick={removeTag} />
        </Box>
    );
};

export default Tags;

Tags.propTypes = {
    filter: PropTypes.object
};
