import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchFilterContext } from '../home/SearchFilters';
import DoubleSlider from '../modules/DoubleSlider';
import Icon from '../modules/Icon';
import TextField from '../modules/TextField';
import { Caption } from '../modules/Typography';
import { SearchContext } from '../pages/Search';

const DateRangeFilter = props => {
    const { addFilter } = useContext(SearchContext);
    const { filters } = useContext(SearchFilterContext);

    const publishStartDate = filters.publication_range.start.split('-')[0];
    const publishEndDate = filters.publication_range.end.split('-')[0];

    const [startDate, setStartDate] = useState(publishStartDate);
    const [endDate, setEndDate] = useState(publishEndDate);

    const addDateFilter = () => {
        const filter = {
            key: props.value,
            value: {
                start: startDate,
                end: endDate
            }
        };
        addFilter(filter);
    };

    const handleDateChange = (min, max) => {
        setStartDate(+min + +publishStartDate);
        setEndDate(+publishStartDate + +max);
    };

    return (
        <div className='Filter__DateRange'>
            <Caption bottomPadded faded>0 Results</Caption>
            <div className='Filter__DateRange__Text flex flex-center'>
                <TextField
                    className='Filter__DateRange__Textfield'
                    value={startDate}
                    disabled
                    padded={false}
                    onChange={e => setStartDate(e.target.value)}
                />
                <Icon name='minus' faded />
                <TextField
                    className='Filter__DateRange__Textfield'
                    value={endDate}
                    disabled
                    padded={false}
                    onChange={e => setEndDate(e.target.value)}
                />
            </div>
            <div onMouseUp={addDateFilter}>
                <DoubleSlider
                    min={0}
                    max={publishEndDate - publishStartDate}
                    step={1}
                    onChange={({ min, max }) => handleDateChange(min, max)}
                />
            </div>
            <div className='Filter__Caption flex'>
                <Caption number={2}>{`${publishStartDate}`}</Caption>
                <Caption number={2}>{`${publishEndDate}`}</Caption>
            </div>
        </div>
    );
};

DateRangeFilter.propTypes = {
    value: PropTypes.string
};

export default DateRangeFilter;
