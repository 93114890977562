import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Body } from './Typography';
import Icon from './Icon';

const BaseAccordion = forwardRef(({
    type = 'default',
    padded = false,
    open,
    className = '',
    toggle,
    caret = true,
    uppercase = false,
    faded = false,
    title,
    children
}, ref) => (
    <div
        className={classNames(`Accordion Accordion--${type}`, { 'Accordion--padded': padded, 'Accordion--tab-open': open }, className)}
        ref={ref}
    >
        <div
            className={classNames('Accordion__header', { 'Accordion--open flex': !caret && open, 'Accordion--closed flex': !caret && !open })}
            onClick={toggle}
        >
            <Body uppercase={uppercase} faded={faded} number={2}>{title}</Body>
            {caret && <Icon name={open ? 'caret-up' : 'caret-down'} />}
        </div>
        {open && children}
    </div>
));

export default BaseAccordion;

BaseAccordion.propTypes = {
    // title of the accordion
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    // style of the accordion
    type: PropTypes.oneOf(['default']),

    // whether or not it intializes open
    children: PropTypes.object,
    className: PropTypes.string,

    // whether or not the accordion is padded below
    padded: PropTypes.bool,
    caret: PropTypes.bool,
    faded: PropTypes.bool,
    uppercase: PropTypes.bool,

    // custom click handler
    toggle: PropTypes.func,
    open: PropTypes.bool
};
