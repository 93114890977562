import React from 'react';
import PropTypes from 'prop-types';

const Help = ({ fill }) => (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>Icons/Action/Bookmark Copy 3</title>
        <defs>
            <path d='M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M11,2 C6.02943725,2 2,6.02943725 2,11 C2,15.9705627 6.02943725,20 11,20 C15.9705627,20 20,15.9705627 20,11 C20,6.02943725 15.9705627,2 11,2 Z M11.01,15 C11.5622847,15 12.01,15.4477153 12.01,16 C12.01,16.5128358 11.6239598,16.9355072 11.1266211,16.9932723 L11,17 C10.4477153,17 10,16.5522847 10,16 C10,15.4871642 10.3860402,15.0644928 10.8833789,15.0067277 L11.01,15 Z M11.596234,5.05310594 C13.5186231,5.38284678 14.9229036,7.0510253 14.9200079,9 C14.9200079,10.2797252 14.1618143,11.2906429 12.9747015,12.0820506 C12.3257819,12.5146631 11.6882584,12.7980066 11.2362274,12.9486834 C10.7122841,13.123331 10.1459642,12.8401708 9.9713166,12.3162274 C9.80914383,11.8297086 10.0417135,11.3066508 10.4952635,11.0945779 L10.7903943,10.9806521 C10.8462119,10.9579447 10.9108082,10.9306012 10.9826124,10.8986883 C11.2857157,10.7639759 11.5884455,10.6025202 11.8653019,10.4179494 C12.5531912,9.95935706 12.9200034,9.47027476 12.9200045,8.9985125 C12.9214551,8.02327937 12.2193138,7.18918878 11.2581187,7.02431827 C10.3503233,6.86860722 9.46146505,7.35270643 9.09287668,8.1819783 L9.03333444,8.33184353 C8.85006232,8.85283276 8.2791457,9.12660656 7.75815647,8.94333444 C7.23716724,8.76006232 6.96339344,8.1891457 7.14666556,7.66815647 C7.79391472,5.82821572 9.67384372,4.7233649 11.596234,5.05310594 Z' id='path-1' />
            <filter x='-204.5%' y='-109.1%' width='518.2%' height='536.4%' filterUnits='objectBoundingBox' id='filter-2'>
                <feOffset dx='1' dy='26' in='SourceAlpha' result='shadowOffsetOuter1' />
                <feGaussianBlur stdDeviation='11' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
                <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.17 0' type='matrix' in='shadowBlurOuter1' />
            </filter>
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search-Result-Copy-2' transform='translate(-570.000000, -498.000000)'>
                <rect fill='#010101' x='0' y='0' width='1440' height='1018' />
                <g id='Group' transform='translate(570.000000, 498.000000)' opacity='0.755332583'>
                    <g id='Combined-Shape' transform='translate(1.000000, 1.000000)'>
                        <use fill='black' fillOpacity='1' filter='url(#filter-2)' xlinkHref='#path-1' />
                        <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-1' />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Help.propTypes = {
    fill: PropTypes.string
};

export default Help;
