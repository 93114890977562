import React from 'react';
import PropTypes from 'prop-types';
import UserCircle from './UserCircle';

const UserCircleList = props => {
    return (
        <div className='UserCircleList flex'>
            {props.users.map((user, idx) => (
                <UserCircle
                    firstName={user.first_name}
                    lastName={user.last_name}
                    key={idx}
                />
            ))}
        </div>
    );
};

export default UserCircleList;

UserCircleList.propTypes = {
    // Array of users in the UserCircle list
    users: PropTypes.array
};

UserCircleList.defaultProps = {

};
