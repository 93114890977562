import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import holder from '../../images/cover.png';
import PropTypes from 'prop-types';
// import tempCollection from '../../images/tempCollection.png';
import { Body, Caption } from '../modules/Typography';
import CollectionPopup from './CollectionPopup';
import { UserContext } from '../../contexts/UserContextProvider';

const calculateTotal = (col) => {
    let total = 0;
    total += col.comic_images.length;
    // col.subcollections.forEach(subCol => {
    //     total += calculateTotal(subCol);
    // });
    return total;
};

const CollectionsGrid = props => {
    const { apiUrl } = useContext(UserContext);
    const rows = props.collections.reduce((prev, curr, idx) => {
        const result = [...prev];
        if (idx % 5 === 0) {
            result.push([]);
        }
        const row = result[result.length - 1];
        row.push(curr);
        return result;
    }, []);
    return (
        <div className='CollectionsGrid'>
            {rows.map((row, idx) => (
                <div className='CollectionsGrid__Row' key={`row-${idx}`}>
                    <div className='CollectionsGrid__Shelf'>
                        <div className='CollectionsGrid__Shelf__left' />
                        <div className='CollectionsGrid__Shelf__right' />
                        <div className='CollectionsGrid__Shelf__front' />
                    </div>
                    <div className='flex flex-start flex-wrap'>
                        {row.map(collection => {
                            const cellTotal = calculateTotal(collection);
                            const imagePath = collection.thumb_url ? `${apiUrl}/image${collection.thumb_url}` : holder;
                            const owner = collection.users.find(user => user.id === collection.owner);
                            const ownerName = `${owner.first_name} ${owner.last_name}`;

                            return (
                                <Link to={`/collections/${collection.id}`} key={collection.id}>
                                    <div className='CollectionsGrid__Collection flex column'>
                                        <div style={{ backgroundImage: `url("${imagePath}")` }} className='CollectionsGrid__Collection__image' />
                                        <div className='flex flex-start'>
                                            <CollectionPopup collection={collection} />
                                            <Body className='CollectionsGrid__Collection__name'>{collection.name}</Body>
                                        </div>
                                        <Caption number={1} className='CollectionsGrid__Collection__total'>({cellTotal} cell{cellTotal !== 1 && 's'})</Caption>
                                        {owner && (
                                            <Caption number={1} className='CollectionsGrid__Collection__total CollectionsGrid__Collection__owner'>
                                                Owner <span>{ownerName}</span>
                                            </Caption>
                                        )}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CollectionsGrid;

CollectionsGrid.propTypes = {
    collections: PropTypes.arrayOf(PropTypes.object) // for now
};
