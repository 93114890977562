import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const DoubleSlider = ({ min, max, onChange, step, ...props }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set up initial filled slider on render
    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, []);

    const handleMinChange = (event) => {
        const value = Math.min(Number(event.target.value), maxVal - 1);
        setMinVal(value);
        minValRef.current = value;

        // Set width of the range to decrease from the left side
        const minPercent = getPercent(value);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
        onChange({ min: value, max: maxVal });
    };

    const handleMaxChange = (event) => {
        const value = Math.max(Number(event.target.value), minVal + 1);
        setMaxVal(value);
        maxValRef.current = value;

        // Set width of the range to decrease from the right side
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(value);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
        onChange({ min: minVal, max: value });
    };

    return (
        <div className='DoubleSlider flex flex-center'>
            <input
                className='DoubleSlider thumb--left'
                type='range'
                min={min}
                max={max}
                step={step}
                value={minVal}
                onChange={handleMinChange}
                style={{ zIndex: minVal > max - 100 && '5' }}
            />
            <input
                className='DoubleSlider thumb--right'
                type='range'
                min={min}
                max={max}
                step={step}
                value={maxVal}
                onChange={handleMaxChange}
            />

            <div className='DoubleSlider__slider'>
                <div className='DoubleSlider__slider__track' />
                <div ref={range} className='DoubleSlider__slider__range' />
            </div>
        </div>
    );
};

export default DoubleSlider;

DoubleSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    step: PropTypes.number
};

DoubleSlider.defaultProps = {
    step: 1
};
