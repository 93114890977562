import React, { useContext, useState } from 'react';
import IconButton from '../modules/IconButton';
import useAfterMountEffect from '../../hooks/useAfterMountEffect';
import CharacterCarousel from '../home/CharacterCarousel';
import Box from '../modules/Box';
import TextField from '../modules/TextField';
import { Link, useHistory } from 'react-router-dom';
import searchOff from '../../images/ico_img-search_off.svg';
import heroBG from '../../images/marvel_heroes_hdr.png';
import logo from '../../images/marvel_logo_xs.svg';
import ImageUploadModal from '../search/ImageUploadModal';
import { DataContext } from '../../contexts/DataContextProvider';
import Tooltip from '../modules/Tooltip';

export const HomeContext = React.createContext();

const Home = props => {
    const history = useHistory();
    const { imagePaths, searchTerm, setSearchTerm } = useContext(DataContext);
    const [file, setFile] = useState(null);
    const [imageUploadModal, setImageUploadModal] = useState(false);

    useAfterMountEffect(() => {
        if (imagePaths.length) {
            history.push('/search');
        }
    }, [imagePaths]);

    return (
        <HomeContext.Provider value={{
            file,
            setFile
        }}
        >
            <div className='Home'>
                <Box
                    className='Home__Box flex column'
                    type='white'
                    padded={false}
                    rounded={false}
                >
                    <div className='Home__Search' style={{ backgroundImage: `url(${heroBG})` }}>
                        <div
                            className='Home__Search__Bar flex'
                        >
                            <TextField
                                className='Home__Search__Text'
                                placeholder='Search'
                                value={searchTerm}
                                padded={false}
                                onChange={e => setSearchTerm(e.target.value)}
                                onSubmit={() => { history.push(`/search?${searchTerm}`); }}
                            />
                            <Link to={`/search?${searchTerm}`}>
                                <IconButton name='search' />
                            </Link>
                            <Tooltip text='Search by Image'>
                                <img className='Home__Search__Image cursor-pointer' src={searchOff} onClick={() => setImageUploadModal(true)} />
                            </Tooltip>
                        </div>
                        <img src={logo} className='Home__Search__Logo' />
                    </div>
                    <div className='Home__Carousel flex column'>
                        <CharacterCarousel />
                    </div>
                </Box>
                {imageUploadModal && <ImageUploadModal handleClose={() => setImageUploadModal(false)} />}
            </div>
        </HomeContext.Provider>
    );
};

Home.propTypes = {

};

export default Home;
