import React, { useState } from 'react';
import TextField from './TextField';
import PropTypes from 'prop-types';
import Button from './Button';
import List from './List';
import DialogWrapper from './DialogWrapper';
import { Caption } from './Typography';
import Loading from './Loading';

const AutoComplete = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showMore, setShowMore] = useState(false);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    let options = props.options;

    if (searchTerm) {
        options = options.filter(option => {
            return searchTerm
                .toLowerCase()
                .split(' ')
                .filter(i => i)
                .every(term => option.text.toLowerCase().includes(term));
        });
    }

    options = options.sort((a, b) => {
        if (a.checked) return -1;
        if (b.checked) return 1;
        return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
    });

    const allItems = options.map(option => ({
        ...option,
        name: option.text,
        value: `${option.value}-${option.checked || false}`
    }));
    let items = [...allItems];
    if (props.maxSize) {
        items = items.slice(0, props.maxSize);
    }

    return (
        <div className={[
            'AutoComplete',
            props.className,
            props.padded ? 'AutoComplete--padded' : null,
            props.fitted ? 'AutoComplete--fitted' : null
        ].filter(i => i).join(' ')}
        >
            <TextField
                label={props.label}
                icon='search'
                onChange={handleSearch}
                placeholder={props.placeholder || ''}
                value={searchTerm}
                className='AutoComplete__search'
                disabled={props.disabled}
            />
            <div className='AutoComplete__overflow'>

                {!props.loading && allItems.length === 0 && <Caption leftPadded={props.padded} rightPadded={props.padded} faded>{props.emptyMessage}</Caption>}

                {props.loading && <Loading />}

                <List
                    className='AutoComplete__list'
                    items={items}
                    onSelect={(val) => {
                        const breakdown = val.split('-');
                        const checked = breakdown.pop();
                        const value = breakdown.join('-');
                        props.handleCheck({
                            value,
                            checked: checked === 'false'
                        });
                    }}
                    buttonText={props.addNewText}
                    handleButton={props.handleAddNew}
                    disabled={props.disabled}
                />
                {props.maxSize && (options.length > props.maxSize) && <Button text='See More' onClick={() => setShowMore(!showMore)} />}
            </div>
            <DialogWrapper
                open={showMore}
                padded
                title={props.showMoreDialogTitle}
                handleCancel={() => setShowMore(false)}
                confirmCancelText='See Less'
                size='medium'
                handleBack={() => setShowMore(false)}
            >
                <List
                    columns={2}
                    className='AutoComplete__dialog__list'
                    items={allItems}
                    onSelect={(val) => {
                        const breakdown = val.split('-');
                        const checked = breakdown.pop();
                        const value = breakdown.join('-');
                        props.handleCheck({
                            value,
                            checked: checked === 'false'
                        });
                    }}
                />
            </DialogWrapper>
        </div>

    );
};

export default AutoComplete;

AutoComplete.propTypes = {
    // className to pass down to tags
    className: PropTypes.string,

    // whether or not it is padded
    padded: PropTypes.bool,

    // whether or not the autocomplete is fitted to the parent container
    fitted: PropTypes.bool,

    // max number of returned options
    maxSize: PropTypes.number,

    // function to handle when an item is checked
    // ({ value = "some-id", checked: false }) => alert(`${value} was ${checked ? "" : "not "} checked`);
    handleCheck: PropTypes.func,

    // dialog title of the show more option
    showMoreDialogTitle: PropTypes.string,

    // message to show if there are no options available
    emptyMessage: PropTypes.string,

    // whether or not the auto complete is loading
    loading: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    addNewText: PropTypes.string,
    handleAddNew: PropTypes.func
};

AutoComplete.defaultProps = {
    className: '',
    padded: false,
    fitted: false,
    showMoreDialogTitle: 'All Options',
    emptyMessage: 'No items to show.',
    loading: false
};
