import React, { createContext, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import GridImage from './GridImage';
import ImageSearch from '../search/ImageSearch';
import GalleryImageHeader from './GalleryImageHeader';
import SearchImageHeader from './SearchImageHeader';
import { Caption, Subtitle } from './Typography';
import ImageDetail from './ImageDetail';
import { SearchContext } from '../pages/Search';

const totalColumns = 4;

const createPlaceHolder = () => {
    const height = Math.floor(Math.random() * (500 - 175 + 1) + 175);
    return {
        size: {
            w: 305,
            h: height
        },
        id: `${height}`,
        thumbnail_path: '#'
    };
};

const initGrid = () => {
    const result = [];
    for (let i = 0; i < totalColumns; i++) {
        const column = [];
        for (let j = 0; j < 10; j++) {
            column.push(createPlaceHolder());
        }
        result.push(column);
    }
    return result;
};

export const ImageGridContext = createContext();

const ImageGrid = props => {
    const { loading } = props.imageHeader ? useContext(SearchContext) : {};
    const [closeCaptioned, setCloseCaptioned] = useState(false);
    const [isBgOn, setBgOn] = useState(false);
    const [layout, setLayout] = useState('grid');
    const [expand, setExpand] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [zoom, setZoom] = useState(100);
    const [detailOpen, setDetailOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [columns, setColumns] = useState([]);
    const [columnHeights, setColumnHeights] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [empty, setEmpty] = useState(props.images.length === 0);

    useEffect(() => {
        setEmpty(props.images.length === 0);
    }, [props.images]);

    const handleScroll = () => {
        const scrollOffset = document.getElementById('ImageGrid').getBoundingClientRect().y - window.innerHeight;
        const smallest = _.min(columnHeights);
        if (smallest + scrollOffset < 2000 && !fetching) {
            setFetching(true);
        }
    };
    const throttleScroll = _.throttle(handleScroll, 300);

    useEffect(() => {
        if (fetching && props.isSearch) {
            props.setOffset(offset => offset + 50);
        }
    }, [fetching]);

    useEffect(() => {
        if (props.isSearch) {
            window.addEventListener('scroll', throttleScroll);
            return () => {
                window.removeEventListener('scroll', throttleScroll);
            };
        }
    }, [columnHeights, fetching]);

    const openDetails = (image) => {
        setDetailOpen(true);
        setImage(image);
    };
    const layoutColumns = () => {
        // Handle any search terms for the images
        const filteredImages = props.images.filter(image => {
            const imageName = image.id; // TODO: Change this to name onces an image name is added to metadata
            return imageName.includes(searchText);
        });

        const columnWidth = 305;
        const heights = filteredImages.map(image => image.size.h * columnWidth / image.size.w);
        const columnHeights = [];

        const columns = filteredImages.length ? filteredImages.reduce((prev, curr, idx) => {
            const columnNumber = idx % totalColumns;
            if (!prev[columnNumber]) {
                prev.push([]);
                columnHeights.push(0);
            }
            prev[columnNumber].push(curr);
            columnHeights[columnNumber] += heights[idx] + 25;
            return prev;
        }, []) : initGrid();
        if (props.isSearch) {
            columns.forEach(column => {
                for (let i = 0; i < 10; i++) {
                    column.push(createPlaceHolder());
                }
            });
        }
        setColumns(columns);
        setColumnHeights(() => columnHeights);
    };

    useEffect(() => {
        layoutColumns();
        setFetching(false);
    }, [props.images]);

    return (
        <ImageGridContext.Provider value={{
            expand,
            zoom,
            searchText,
            layout,
            closeCaptioned,
            setCloseCaptioned,
            setLayout,
            setSearchText,
            setZoom,
            setExpand
        }}
        >
            <div className='ImageGrid__Container' id='ImageGrid'>
                {!props.imageHeader && <GalleryImageHeader />}
                <div className='ImageGrid__Inner__Container'>
                    {/* {isBgOn && <div className='DialogWrapper__background' style={{ zIndex: 3 }} />} */}
                    {props.imageHeader && <SearchImageHeader />}
                    <div className='ImageGrid__Image__Container flex align-flex-start'>
                        {props.upload && <ImageSearch />}
                        {empty || loading ? (
                            <div className='ImageGrid__Empty flex column'>
                                {props.imageHeader ? (
                                    <>
                                        <Subtitle number={1}>{loading ? 'Searching the multiverse for images' : 'No Results'}</Subtitle>
                                        <Caption className='CollectionsGallery__Header__Caption'>{loading && 'One moment please'}</Caption>
                                        <div className={`ImageGrid__Empty${loading ? '--searching' : '--empty'}`} />
                                    </>
                                ) : (
                                    <>
                                        <Subtitle number={1}>Empty collection</Subtitle>
                                        <Caption className='CollectionsGallery__Header__Caption'>Please add images</Caption>
                                        <div className={`ImageGrid__Empty${'--empty'}`} />
                                    </>
                                )}
                            </div>
                        ) : (
                            <div
                                className={`ImageGrid ${props.upload ? 'ImageGrid--upload' : ''} flex flex-start align-flex-start`}
                                style={{ transform: `scale(${zoom / 100})` }}
                            >
                                {columns.map((column, columnIdx) => (
                                    <div className='ImageGrid__Column flex column flex-start' key={`column-${columnIdx}`}>
                                        {column.map((image, idx) => (
                                            <GridImage
                                                key={`${image.id}-${columnIdx}-${idx}`}
                                                image={image}
                                                setBgOn={setBgOn}
                                                isBgOn={isBgOn}
                                                idx={idx}
                                                hasCheckbox={props.imageHeader}
                                                ccOn={closeCaptioned}
                                                isSearch={props.isSearch}
                                                openDetails={openDetails}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                        {detailOpen && <ImageDetail className='ImageGrid__ImageDetail' handleClose={() => setDetailOpen(false)} image={image} collectionId={props.collectionId} collectionName={props.collectionName} />}
                    </div>
                </div>
            </div>
        </ImageGridContext.Provider>
    );
};

ImageGrid.propTypes = {
    images: PropTypes.array,
    upload: PropTypes.bool,
    imageHeader: PropTypes.bool,
    isSearch: PropTypes.bool,
    collectionId: PropTypes.string,
    setOffset: PropTypes.func,
    collectionName: PropTypes.string
};

ImageGrid.defaultProps = {
    imageHeader: true
};
export default ImageGrid;
