import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { Caption } from './Typography';
import CustomSurfaceOption from './CustomSurfaceOption';
import { AddMemberContext } from '../collections/AddMember';

const AddMemberSelect = props => {
    const menuRef = useRef(null);
    const { setSearching } = useContext(AddMemberContext);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const openMenu = () => {
        setOpen(true);
    };
    const closeMenu = () => {
        setOpen(false);
    };

    // handle organic close
    const handleClick = useCallback((e) => {
        if (menuRef && !menuRef.current.contains(e.target)) {
            setOpen(false);
            setSearching(false);
        }
    }, [setOpen]);

    useEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    // filter search
    useEffect(() => {
        if (!open) setSearch('');
    }, [open]);

    // handle search by name or email
    const options = search ? props.options.filter(option => {
        const { text, value: { email } } = option;
        return (
            `${text.toLowerCase()}${email.toLowerCase()}`.includes(search.toLowerCase())
        );
    }) : props.options;

    const handleSearching = (value) => {
        setSearching(!!value.length);
        setSearch(value);
    };

    return (
        <div className={[
            'AddMemberSelect',
            `AddMemberSelect--${(search && open) ? 'open' : 'close'}`,
            props.className,
            'flex column'
        ].filter(i => i).join(' ')}
        >
            <TextField
                className='AddMemberSelect__TextField'
                value={search}
                padded={false}
                onChange={e => handleSearching(e.target.value)}
                onClick={openMenu}
                placeholder={props.placeholder}
            />
            <div className={[
                'AddMemberSelect__MenuSurface',
                `AddMemberSelect__MenuSurface--${(search && open) ? 'open' : 'close'}`
            ].filter(i => i).join(' ')}
            >
                <ul
                    className='AddMemberSelect__MenuSurface__menu'
                    ref={menuRef}
                >
                    {open && search && !options.length && (
                        <li className='padded'>
                            <Caption faded>No user found.</Caption>
                        </li>
                    )}
                    {options.map((option, index) => (
                        <CustomSurfaceOption key={index} {...option} closeParentMenu={closeMenu} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AddMemberSelect;

AddMemberSelect.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string
};

AddMemberSelect.defaultProps = {
    className: '',
    placeholder: ''
};
