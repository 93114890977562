import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BaseAccordion from './BaseAccordion';
import { SearchFilterContext } from '../home/SearchFilters';
import useAfterMountEffect from '../../hooks/useAfterMountEffect';

export const FilterContext = React.createContext();

const Filter = ({
    classnames,
    initOpen = false,
    uppercase = true,
    title,
    children,
    ...props
}) => {
    const { filtersOpen, setFiltersOpen } = useContext(SearchFilterContext);
    const [open, setOpen] = useState(initOpen);
    const accordionRef = useRef(null);
    const toggle = () => setOpen(prev => !prev);

    // handle organic close
    const handleClick = useCallback((e) => {
        if (accordionRef && !accordionRef.current.contains(e.target)) {
            setOpen(false);
        }
    }, [setOpen]);

    useEffect(() => {
        const tempFilters = { ...filtersOpen };
        tempFilters[props.filterId] = open;
        setFiltersOpen(tempFilters);
    }, [open]);

    useAfterMountEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    return (
        <FilterContext.Provider value={{ setOpen }}>
            <div className={classNames('Filter', classnames)}>
                <BaseAccordion
                    className='Filter__Dropdown'
                    caret={false}
                    faded
                    uppercase={uppercase}
                    title={title}
                    open={open}
                    toggle={toggle}
                />
                <div ref={accordionRef}>
                    {open && children}
                </div>
            </div>
        </FilterContext.Provider>
    );
};

export default Filter;

Filter.propTypes = {
    children: PropTypes.element,
    classnames: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initOpen: PropTypes.bool,
    uppercase: PropTypes.bool,
    filterId: PropTypes.number
};
