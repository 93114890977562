import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import { Subtitle } from '../modules/Typography';
import fetch from '../../utils/fetch';
import EditGroupItem from './EditGroupItem';
import { UserContext } from '../../contexts/UserContextProvider';
import { EditContext } from '../pages/EditHomePage';
import { GroupContext } from './Group';

const GroupItem = props => {
    const { apiUrl } = useContext(UserContext);
    const { refetch } = useContext(EditContext);
    const { handleOpen } = useContext(GroupContext);
    const { item, open } = props;

    const handleDelete = async () => {
        await fetch(`/groupmodel/${item.id}`, {
            method: 'DELETE'
        });
        refetch();
    };

    const thumb = item.thumbnailUrl ? `${apiUrl}/image${item.thumbnailUrl}` : '';
    return (
        <div className={`Edit__Group__Card Edit__Group__Item ${open ? 'open' : ''}`}>
            {open ? (
                <EditGroupItem
                    handleClose={() => handleOpen(null)}
                    item={item}
                />
            ) : (
                <div className='flex'>
                    <div>
                        <Icon name='caret-right' className='Edit__Group__Item__caret pointer' onClick={() => handleOpen(open ? null : item.id)} />
                        <div className='flex flex-start'>
                            <div className='Edit__Group__Item__Image' style={{ backgroundImage: `url("${thumb}")` }} />
                            <div className='flex column'>
                                <Subtitle number={3}>{item.title}</Subtitle>
                                <Subtitle number={2} className='half-text'>{item.subtitle}</Subtitle>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button type='link' onClick={() => handleOpen(item.id)}>Edit</Button>
                        <Icon name='trash-can' className='pointer' onClick={handleDelete} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default GroupItem;

GroupItem.propTypes = {
    item: PropTypes.object,
    open: PropTypes.bool
};
