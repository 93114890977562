import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ fill }) => (
    <svg width='25px' height='25px' viewBox='0 0 25 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>icons8-search-more</title>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search-Result-Copy-2' transform='translate(-605.000000, -499.000000)' fillRule='nonzero'>
                <g id='icons8-search-more' style={{ mixBlendMode: 'luminosity' }} transform='translate(605.000000, 499.000000)'>
                    <polygon id='Path' fill='#DB345F' points='16 17.7165437 17.7165437 16 25 23.2834563 23.2834563 25' />
                    <path d='M20,10 C20,15.5224612 15.5224612,20 10,20 C4.47753875,20 0,15.5224612 0,10 C0,4.47753875 4.47753875,0 10,0 C15.5224612,0 20,4.47753875 20,10' id='Path' fill='#BABABA' />
                    <polygon id='Path' fill='#BABABA' points='18 19.7185487 19.7179666 18 25 23.2814513 23.2796612 25' />
                    <path d='M10,2 C14.4182695,2 18,5.58173046 18,10 C18,14.4182695 14.4182695,18 10,18 C5.58173046,18 2,14.4182695 2,10 C2,5.58173046 5.58173046,2 10,2 Z' id='Path' fill='#040404' />
                    <path d='M5.22222222,8 C4.54665783,8 4,8.67089825 4,9.5 C4,10.3291017 4.54665783,11 5.22222222,11 C5.89778661,11 6.44444444,10.3291017 6.44444444,9.5 C6.44444444,8.67089825 5.89778661,8 5.22222222,8 Z M9.5,8 C8.82443561,8 8.27777778,8.67089825 8.27777778,9.5 C8.27777778,10.3291017 8.82443561,11 9.5,11 C10.1755644,11 10.7222222,10.3291017 10.7222222,9.5 C10.7222222,8.67089825 10.1755644,8 9.5,8 Z M13.7777778,8 C13.1022134,8 12.5555556,8.67089825 12.5555556,9.5 C12.5555556,10.3291017 13.1022134,11 13.7777778,11 C14.4533422,11 15,10.3291017 15,9.5 C15,8.67089825 14.4533422,8 13.7777778,8 Z' id='Shape' fill='#B6BCBC' />
                </g>
            </g>
        </g>
    </svg>
);

Search.propTypes = {
    fill: PropTypes.string
};

export default Search;
