import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../modules/Table';
import IconButton from '../modules/IconButton';
import { Body, Caption } from '../modules/Typography';
import UserCircle from '../modules/UserCircle';
import { AdminContext } from '../pages/Admin';

const UsersTable = props => {
    const pageSizeOptions = ['10', '25', '50', '100'];
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const { searchTerm, allUsers, openUserAction } = useContext(AdminContext);

    useEffect(() => {
        setOffset(0);
    }, [searchTerm]);

    const search = (data) => {
        const tempUsers = data.filter(user => {
            const { first_name: firstName, last_name: lastName, email, role } = user;
            const name = `${firstName} ${lastName}`;
            const searchTermLowerCase = searchTerm.toLowerCase();
            return name.toLowerCase().includes(searchTermLowerCase) ||
                email.toLowerCase().includes(searchTermLowerCase) ||
                role.toLowerCase().includes(searchTerm);
        });
        return tempUsers;
    };

    const filteredData = search(allUsers);

    const tableData = filteredData.map(user => {
        const { id, first_name: firstName, last_name: lastName, email, role, active, last_login: lastLogin } = user;
        const date = new Date(lastLogin + 'Z');
        const options = { hour12: true, timeZoneName: 'short' };
        return (
            {
                id,
                userStatus: { firstName, lastName, active },
                email,
                role: role || 'N/A',
                login: lastLogin ? date.toLocaleString('en-US', options) : 'No previous login'
            }
        );
    });

    const columns = [
        {
            Header: 'USER/STATUS',
            accessor: 'userStatus',
            width: 200,
            Cell: cell => {
                const { firstName, lastName, active } = cell.row.original.userStatus;
                return (
                    <div className='flex flex-start'>
                        <UserCircle
                            className='Admin__Circle'
                            firstName={firstName}
                            lastName={lastName}
                            style='light'
                        />
                        <div className='flex column'>
                            <Body className='Admin__Text'>{`${firstName} ${lastName}`}</Body>
                            <div className='flex'>
                                <div className={`Admin__Active Admin__Active--${active ? 'active' : 'blocked'}`} />
                                <Caption className='Admin__Text'>{active ? 'Active' : 'Blocked'}</Caption>
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
            Header: 'EMAIL',
            accessor: 'email',
            width: 200,
            Cell: cell => {
                const { email } = cell.row.original;
                return (
                    <div className='Admin__Email'>
                        <Caption className='' heavy number={1}>EMAIL</Caption>
                        <Caption className='Admin__Text--gold all-capitalize'>{email}</Caption>
                    </div>
                );
            }
        },
        {
            Header: 'ROLE',
            accessor: 'role',
            width: 100,
            Cell: cell => (
                <div className='Admin__Role'>
                    <Caption heavy number={1}>ROLE</Caption>
                    <Caption className='Admin__Text--gold all-capitalize' number={1}>{cell.row.original.role}</Caption>
                </div>
            )
        },
        {
            Header: 'LOGIN',
            accessor: 'login',
            Cell: cell => (
                <>
                    <Caption heavy number={1}>LAST LOGIN</Caption>
                    <Caption className='Admin__Text Admin__Text--faded'>{cell.row.original.login}</Caption>
                </>
            )
        },
        {
            accessor: 'edit',
            width: 30,
            Cell: cell => (
                <IconButton
                    name='edit'
                    size='small'
                    onClick={() => {
                        const { id, email, userStatus, role } = cell.row.original;
                        const user = {
                            id: id,
                            email: email,
                            firstName: userStatus.firstName,
                            lastName: userStatus.lastName,
                            role: role,
                            active: userStatus.active
                        };
                        openUserAction('edit', user);
                    }}
                />
            )
        }
    ];
    return (
        <div className={`UsersTable ${props.className || ''}`}>
            <Table
                columns={columns}
                data={tableData}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffset={setOffset}
                pagination
                totalItems={filteredData.length}
                pageSizeOptions={pageSizeOptions}

            />
        </div>
    );
};

export default UsersTable;

UsersTable.propTypes = {
    className: PropTypes.string
};
