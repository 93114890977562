import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserCircle from './UserCircle';
import { Caption } from './Typography';
import IconButton from './IconButton';
import { AddMemberContext } from '../collections/AddMember';

const CollectionMember = ({ first_name: firstName, last_name: lastName, email, id, ...props }) => {
    const { handleDeleteMember } = useContext(AddMemberContext);
    return (
        <div className='CollectionMember flex'>
            <UserCircle
                className='CollectionMember__UserCircle'
                firstName={firstName}
                lastName={lastName}
            />
            <div className='CollectionMember__member flex column'>
                <Caption className='CollectionMember__Name CollectionMember--black all-capitalize' ellipsis>{`${firstName} ${lastName}`}</Caption>
                <Caption className='CollectionMember__Email CollectionMember--black all-capitalize' number={2} ellipsis>{email}</Caption>
            </div>
            <Caption className={`CollectionMember${props.creator ? '--black CollectionMember__creator' : '--red'}`} number={2}>{props.creator ? 'Creator' : 'Collaborator'}</Caption>
            {!props.creator && <IconButton className='CollectionMember--red' name='trash-can' size='small' onClick={() => handleDeleteMember(id)} />}
        </div>
    );
};

export default CollectionMember;

CollectionMember.propTypes = {
    name: PropTypes.string,

    // Member email
    email: PropTypes.string,

    // Member id number
    id: PropTypes.number,

    // Member role
    role: PropTypes.object,

    // Can the collection member be deleted
    delete: PropTypes.bool,

    // Bool if member is the collection creator
    creator: PropTypes.bool,

    // Member's first name
    first_name: PropTypes.string,

    // Member's last name
    last_name: PropTypes.string
};
