import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from './Typography';

const Tooltip = props => {
    return (
        <div className='Tooltip__Wrapper'>
            {props.children}
            <Caption className='Tooltip__Text' color='default'>{props.text}</Caption>
        </div>
    );
};

export default Tooltip;

Tooltip.propTypes = {
    children: PropTypes.object,
    text: PropTypes.string
};
