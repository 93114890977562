import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../modules/AutoComplete';
import { Caption } from '../modules/Typography';
import { SearchFilterContext } from '../home/SearchFilters';
import { SearchContext } from '../pages/Search';
import { FilterContext } from '../modules/Filter';
import TextField from '../modules/TextField';

const SearchFilter = props => {
    const sliderMin = 0;
    const sliderMax = 1;

    const { setOpen } = useContext(FilterContext);
    const { addFilter } = useContext(SearchContext);
    const { filters } = useContext(SearchFilterContext);

    const [searchTerm, setSearchTerm] = useState('');

    const [sliderValue, setSliderValue] = useState(0.8);

    const setFilter = (value) => {
        const filter = {
            key: props.value,
            value: {
                text: value
            }
        };
        if (props.slider) filter.value.score = sliderValue;
        addFilter(filter);
        setOpen(false);
    };

    const handleDropdownSelect = ({ value }) => {
        setFilter(value);
    };

    return (
        <div className='Filter__SearchFilter'>
            {props.title === 'text' ? (
                <div>
                    <TextField
                        className='Filter__SearchFilter__Textfield'
                        placeholder='Search...'
                        icon='search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onSubmit={() => setFilter(searchTerm)}
                    />
                </div>
            ) : (
                <AutoComplete
                    className='Filter__Dropdown__Search'
                    showMoreDialogTitle={props.title.toUpperCase()}
                    maxSize={5}
                    emptyMessage='0 RESULTS'
                    options={filters[props.value] ? filters[props.value].map(option => ({
                        text: option,
                        value: option
                    })) : []}
                    placeholder='Search...'
                    handleCheck={handleDropdownSelect}
                />
            )}
            {props.slider && (
                <div className='Filter__Slider' style={{ paddingTop: '15px' }}>
                    <input
                        className='Slider slider-progress'
                        type='range'
                        min={0}
                        max={1}
                        value={sliderValue}
                        step={0.1}
                        onChange={e => setSliderValue(e.target.value)}
                        style={{ "--max": `${sliderMax}`, "--min": `${sliderMin}`, "--value": `${sliderValue}` }} // eslint-disable-line
                    />
                    <div className='Filter__Caption flex'>
                        <Caption number={2}>OPTIONAL</Caption>
                        <Caption number={2}>REQUIRED</Caption>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchFilter;

SearchFilter.propTypes = {
    slider: PropTypes.bool,

    // Filter title used for showMore
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    // Filter value used to populate filter dropdown
    value: PropTypes.string
};
