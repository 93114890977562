import React, { useEffect, useState, useContext } from 'react';
import fetch from '../../utils/fetch';
import SearchFilters from '../home/SearchFilters';
import ComicDrawer from '../search/ComicDrawer';
import ImageGrid from '../modules/ImageGrid';
import { useLocation, useHistory } from 'react-router-dom';
import { DataContext } from '../../contexts/DataContextProvider';

export const SearchContext = React.createContext();

const Search = props => {
    const location = useLocation();
    const history = useHistory();
    const { imagePaths, setImagePaths, upload, setUpload, searchTerm, setSearchTerm } = useContext(DataContext);
    const [images, setImages] = useState([]);
    const [collections, setCollections] = useState([]);
    const [comics, setComics] = useState([]);
    const [filters, setFilters] = useState([]);
    const [selected, setSelected] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const [filterMore, setFilterMore] = useState(false);
    const [searchMore, setSearchMore] = useState(false);
    const [share, setShare] = useState(false);
    const [imageIds, setImageIds] = useState([]);
    const [sortBy, setSort] = useState('');
    const [offset, setOffset] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchCollections = async () => {
        const response = await fetch('/usercollections/all/name');
        setCollections(response);
    };

    const fetchImages = async () => {
        if (offset === 0) {
            setImages([]);
            setLoading(true);
        }

        const response = await fetch('/images', {
            method: 'POST',
            body: {
                searchTerm: searchTerm || '',
                filters,
                imageIds,
                sortBy,
                imagePaths,
                num: offset ? 50 : 100,
                offset
            }
        });
        if (offset) {
            setImages([...images, ...response]);
        } else {
            setImages(response);
        }
        setLoading(false);
    };

    useEffect(() => {
        return () => {
            setUpload(false);
        };
    }, []);

    useEffect(() => {
        const comics = images.reduce((prev, curr) => {
            const comic = curr.comic_info;
            if (!prev[comic.id]) {
                prev[comic.id] = { ...comic, total: 0 };
            }
            prev[comic.id].total++;
            return prev;
        }, {});
        setComics(comics);
    }, [images]);

    useEffect(() => {
        fetchCollections();
        if (location.search) {
            setImages([]);
            setUpload(false);
            setImagePaths([]);
            setSelected({});
        }
    }, [location]);

    useEffect(() => {
        setSelected({});
    }, [allSelected]);

    useEffect(() => {
        setOffset(0);
        setFetching(true);
    }, [filters, imageIds, sortBy]);

    useEffect(() => {
        if (imagePaths.length) {
            setSearchTerm('');
            setFilters([]);
            setImageIds([]);
        }
    }, [imagePaths]);

    useEffect(() => {
        setFetching(true);
    }, [offset]);

    useEffect(() => {
        const asyncFetch = async () => {
            await fetchImages();
        };
        if (fetching) {
            asyncFetch();
            setFetching(false);
        }
    }, [fetching]);

    useEffect(() => {
        if (filterMore) {
            const imageIds = (allSelected ? images.map(image => image.id) : Object.keys(selected).filter(key => selected[key]))
                .map(id => ({ image_id: id, region: { top: 0.0, left: 0.0, width: 1.0, height: 1.0 } }));
            setImageIds(imageIds);
            setFilterMore(false);
        }
    }, [filterMore]);

    useEffect(() => {
        if (searchMore) {
            const imageIds = (allSelected ? images.map(image => image.id) : Object.keys(selected).filter(key => selected[key]))
                .map(id => ({ image_id: id, region: { top: 0.0, left: 0.0, width: 1.0, height: 1.0 } }));
            setImageIds(imageIds);
            history.push('/search');
            setSearchMore(false);
        }
    }, [searchMore]);

    const handleFilter = () => setFilterMore(true);
    const handleSearchMore = () => setSearchMore(true);
    const handleShare = () => setShare(true);

    const addFilter = (newFilter) => {
        setFilters(filters => [...filters.filter(oldFilter => oldFilter.key !== newFilter.key), newFilter]);
    };

    return (
        <SearchContext.Provider value={{
            addFilter,
            comics,
            collections,
            fetchCollections,
            selected,
            allSelected,
            setSelected,
            setFilters,
            setAllSelected,
            handleShare,
            handleFilter,
            handleSearchMore,
            loading,
            share,
            filters,
            imageIds,
            sortBy,
            setSort
        }}
        >
            <div className='Search'>
                <SearchFilters />
                <div className='Search__Content flex align-flex-start'>
                    <ComicDrawer />
                    <ImageGrid
                        images={images}
                        upload={upload}
                        isSearch
                        offset={offset}
                        setOffset={setOffset}
                        imageTotal={offset ? 50 : 100}
                    />
                </div>
            </div>
        </SearchContext.Provider>

    );
};

Search.propTypes = {

};

export default Search;
