import React, { useContext } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import IconButton from '../modules/IconButton';
import logo from '../../images/logo.svg';
import collections from '../../images/ico_collection.svg';

import { Subtitle } from '../modules/Typography';
import Button from '../modules/Button';
import Select from '../modules/Select';
import { UserContext } from '../../contexts/UserContextProvider';

const TopNav = props => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const isEditing = pathname === '/edit';

    const options = [{ text: 'Admin', value: 'admin' }, { text: 'Sign Out', value: 'signOut' }];

    const logout = () => {
        const cookies = new Cookies();
        cookies.remove('access_token');
        cookies.remove('id_token');
        cookies.remove('user_id');
        history.go(0);
    };

    const adminSelect = (value) => {
        switch (value) {
        case 'admin':
            history.push('/admin');
            break;
        default:
            logout();
        }
    };

    return (
        <div className='TopNav'>
            <Helmet><title>Marvel Comic Library</title></Helmet>
            {!isEditing && user.role === 'ADMIN' && (
                <div className='Home__Edit flex'>
                    <Subtitle>Hi, {user.firstName}!</Subtitle>
                    <Button type='primary' trailingIcon='edit' link='/edit'>Edit</Button>
                </div>
            )}
            <div className={`TopNav__Header flex ${isEditing ? 'editing' : ''}`}>
                <div className='TopNav__Header__left' />
                <Link to='/'>
                    <img
                        src={logo} className='TopNav__logo cursor-pointer'
                    />
                </Link>
                <div className='TopNav__Header__right flex'>
                    <Link to='/collections'>
                        <img src={collections} className='TopNav__Collection' />
                    </Link>
                    {user.role === 'ADMIN' ? (
                        <Select
                            className='TopNav__User'
                            styleType='dark'
                            placeholder='User'
                            options={options}
                            onChange={({ value }) => { adminSelect(value); }}
                        />
                    ) : (
                        <IconButton name='logout' onClick={logout} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopNav;
