import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import fetch from '../../utils/fetch';
import PropTypes from 'prop-types';
import TextField from '../modules/TextField';
import Icon from '../modules/Icon';
import Filter from '../modules/Filter';
import filterConfig from '../filters/filterConfig';
import ImageUploadModal from '../search/ImageUploadModal';
import SearchHelp from '../search/SearchHelp';
import DateRangeFilter from '../filters/DateRangeFilter';
import SearchFilter from '../filters/SearchFilter';
import Tags from '../modules/Tags';
import { SearchContext } from '../pages/Search';
import Tooltip from '../modules/Tooltip';
import { DataContext } from '../../contexts/DataContextProvider';

export const SearchFilterContext = React.createContext();

const SearchFilters = props => {
    const { setFilters: setSearchFilters, filters: searchedFilters } = useContext(SearchContext) || {};
    const { searchTerm } = useContext(DataContext);
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
    const history = useHistory();
    const location = useLocation();
    const [filters, setFilters] = useState(null);
    const [imageUploadModal, setImageUploadModal] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState({});
    const filterType = props => ({
        search: <SearchFilter slider={props.slider} title={props.title} value={props.value} />,
        range: <DateRangeFilter value={props.value} />
    });

    useEffect(() => {
        // Get the filters from the api
        const getFilters = async () => {
            const response = await fetch('/images/filters');
            setFilters(response);
        };
        getFilters();
    }, []);

    const handleSubmit = () => {
        history.push(`/search?${localSearchTerm}`);
        if (setSearchFilters) {
            setSearchFilters([]);
        }
    };

    return (
        <SearchFilterContext.Provider value={{ filters, filtersOpen, setFiltersOpen }}>
            <div className='HomeSearch'>
                <div className='relative'>
                    <div className='flex'>
                        <TextField
                            className='HomeSearch__Textfield'
                            value={localSearchTerm}
                            icon='search'
                            onChange={e => setLocalSearchTerm(e.target.value)}
                            onSubmit={handleSubmit}
                        />
                        <div className='HomeSearch__Tags flex'>
                            {searchedFilters?.map((filter, idx) => (
                                <Tags filter={filter} key={idx} />
                            ))}
                        </div>
                        <div className='HomeSearch__Icons flex align-center flex-end'>
                            <Tooltip className='HomeSearch__Tooltip' text='Search by Image'>
                                <Icon className='HomeSearch__Icon cursor-pointer' name='photo' onClick={() => setImageUploadModal(true)} />
                            </Tooltip>
                            <Icon className='HomeSearch__Icon pointer' name='info' onClick={() => setHelpOpen(!helpOpen)} />
                        </div>
                    </div>
                </div>
                {location.pathname.includes('search') && (
                    <div className='HomeSearch__Filter__Placeholder'>
                        <div className={`HomeSearch__Filter__Container HomeSearch__Filter__Container${Object.values(filtersOpen).includes(true) ? '--open' : '--closed'} flex flex-center`}>
                            <div className='HomeSearch__Filter flex align-flex-start'>
                                {filters && filterConfig.map((filter, idx) => (
                                    <Filter
                                        key={idx}
                                        filterId={idx}
                                        {...filter}
                                    >
                                        {filterType(filter)[filter.type]}
                                    </Filter>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {imageUploadModal && <ImageUploadModal home={false} handleClose={() => setImageUploadModal(false)} />}
                <SearchHelp open={helpOpen} handleCancel={() => setHelpOpen(false)} />
            </div>
        </SearchFilterContext.Provider>
    );
};

export default SearchFilters;

SearchFilters.propTypes = {
    title: PropTypes.string,
    slider: PropTypes.bool,
    value: PropTypes.string
};
