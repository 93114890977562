import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export const DataContext = React.createContext();

const DataComponent = ({ children }) => {
    const location = useLocation();
    const [imagePaths, setImagePaths] = useState([]);
    const [searchTerm, setSearchTerm] = useState(location.search?.split('?')[1] || '');
    const [upload, setUpload] = useState(false);

    useEffect(() => {
        setSearchTerm(location.search.split('?')[1] || '');
    }, [location]);

    return (
        <DataContext.Provider value={{
            imagePaths,
            setImagePaths,
            upload,
            setUpload,
            searchTerm,
            setSearchTerm
        }}
        >
            {children}
        </DataContext.Provider>
    );
};

DataComponent.propTypes = {
    children: PropTypes.element
};

export default DataComponent;
