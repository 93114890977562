import React, { useEffect, useState, useContext } from 'react';
import { Heading, Subtitle, Body } from '../modules/Typography';
import { Prompt, useHistory } from 'react-router-dom';
import fetch from '../../utils/fetch';
import Group from '../edit/Group';
import Icon from '../modules/Icon';
import ellipses from '../../images/ico-ellipses.svg';
import { UserContext } from '../../contexts/UserContextProvider';

export const EditContext = React.createContext();

const EditHomePage = props => {
    const history = useHistory();
    const [groups, setGroups] = useState([]);
    const [isDirty, setDirty] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { user } = useContext(UserContext);

    const fetchGroups = async () => {
        const response = await fetch('/groups');
        setGroups(response);
        setDirty(false);
    };

    useEffect(() => {
        if (user.role !== 'ADMIN') {
            history.push('/');
        } else {
            fetchGroups();
        }
    }, []);

    useEffect(() => {
        if (selectedGroup) {
            setSelectedGroup(groups.find(group => group.id === selectedGroup.id));
        }
    }, [groups]);

    return (
        <EditContext.Provider value={{ refetch: fetchGroups, setDirty }}>
            <div className='Edit'>
                <div className='Home__Edit Edit__TopNav flex'>
                    <Subtitle>Hi, {user.firstName}!</Subtitle>
                </div>
                <Icon name='caret-left' className='Edit__caret pointer' onClick={() => history.goBack()} />
                <Subtitle className='Edit__exit' block number={3}>Exit</Subtitle>
                <Heading block number={5}>HOME PAGE CMS</Heading>
                <div className='Edit__Groups flex align-flex-start flex-start'>
                    <div>
                        {groups.map(group => (
                            <div className={`Edit__Groups__Name ${group.id === selectedGroup?.id ? 'active' : ''}`} key={group.id} onClick={() => setSelectedGroup(group)}>
                                <img className='Edit__Groups__Icon' src={ellipses} />
                                <Body>{group.name}</Body>
                            </div>
                        ))}
                        <div className={`Edit__Groups__Name ${selectedGroup && !selectedGroup.id ? 'active' : ''}`} onClick={() => setSelectedGroup({})}>
                            <Icon name='plus' className='Edit__Groups__Icon ImageGrid__item__SaveMenu__icon Icon__Circle' />
                            <Subtitle>Add Group</Subtitle>
                        </div>
                    </div>
                    {selectedGroup && <Group group={selectedGroup} />}
                </div>
                <Prompt when={isDirty} message='Are you sure you want to leave?' />
            </div>
        </EditContext.Provider>
    );
};

export default EditHomePage;
