import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Body, Caption } from '../modules/Typography';
import Box from '../modules/Box';
import { UserContext } from '../../contexts/UserContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';

const CarouselCard = props => {
    const { searchQuery, subtitle, thumbnailUrl, title } = props.character;
    const { apiUrl } = useContext(UserContext);
    const { setSearchTerm } = useContext(DataContext);

    const imgPath = `${apiUrl}/image${thumbnailUrl || ''}`;

    return (
        <Link to={`/search?${searchQuery}`} onClick={() => setSearchTerm(searchQuery)}>
            <Box
                className='CarouselCard__Box flex column cursor-pointer'
                type='gray-filled'
                rounded={false}
                padded={false}
            >
                <div className='CarouselCard__Character'>
                    <div className='CarouselCard__Character__Image' style={{ backgroundImage: `url(${imgPath})` }} />
                </div>
                <Box
                    className='CarouselCard__Info flex column'
                    type='gray-filled'
                    rounded={false}
                >
                    <Body number={2} className='CarouselCard__Info__Text Info-Title'>
                        {title?.toUpperCase() || 'Unknown'}
                    </Body>
                    <Caption className='CarouselCard__Info__Text' capitalize number={1}>
                        {subtitle || ''}
                    </Caption>
                </Box>
            </Box>
        </Link>
    );
};

export default CarouselCard;

CarouselCard.propTypes = {
    // Marvel character object
    character: PropTypes.object,

    // Codename of marvel character
    title: PropTypes.string,

    // Full name of marvel character
    subtitle: PropTypes.string,

    // url for character image
    thumbnailUrl: PropTypes.string,

    // search term for character
    searchQuery: PropTypes.string
};
