import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import fetch from '../../utils/fetch';
import CarouselCard from './CarouselCard';
import Button from '../modules/Button';
import { Caption } from '../modules/Typography';
import bgDiagonal from '../../images/title_bg.svg';
import Select from '../modules/Select';
import Loading from '../modules/Loading';

const CharacterCarousel = props => {
    const sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const [characterGroups, setCharacterGroups] = useState([]);
    const [characterImages, setCharacterImages] = useState([]);
    const [groupId, setGroupId] = useState('');

    var settings = {
        dots: false,
        arrows: false,
        draggable: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        initialSlide: 0,
        beforeChange: (current, next) => {
            setSlideIndex(next);
        },
        responsive: [
            {
                breakpoint: 2335,
                settings: {
                    slidesToShow: 10
                }
            }, {
                breakpoint: 2170,
                settings: {
                    slidesToShow: 9
                }
            }, {
                breakpoint: 1970,
                settings: {
                    slidesToShow: 8
                }
            }, {
                breakpoint: 1770,
                settings: {
                    slidesToShow: 7
                }
            }, {
                breakpoint: 1570,
                settings: {
                    slidesToShow: 6
                }
            }, {
                breakpoint: 1340,
                settings: {
                    slidesToShow: 5
                }
            }, {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 940,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 740,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const fetchGroup = async () => {
        const response = await fetch('/groups');
        setCharacterGroups(response);
        setGroupId(response[0]?.id || '');
        setCharacterImages((response[0]?.comic_models || [])
            .sort((a, b) => {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                return 1;
            }));
    };

    useEffect(() => { fetchGroup(); }, []);

    const handleStart = () => {
        sliderRef.current.slickGoTo(0);
        setSlideIndex(0);
    };
    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };
    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handleEnd = () => {
        sliderRef.current.slickGoTo(characterImages.length - 1);
    };

    const groups = characterGroups.map(grp => {
        return { text: grp.name, value: grp.id };
    });

    return (
        <>
            <div className='CharacterCarousel__Options flex'>
                <>
                    <img className='CharacterCarousel__Diagonal' src={bgDiagonal} />
                    <Select
                        placeholder='Select Group'
                        className='CharacterCarousel__Heading'
                        styleType='dark'
                        bodyText
                        selectedValue={groupId}
                        options={groups}
                        onChange={({ value }) => setCharacterImages(characterGroups.find(grp => grp.id === value)?.comic_models || [])}
                    />
                    {/* <Body className='CharacterCarousel__Heading'>MARVEL CHARACTERS</Body> */}
                </>
            </div>
            <div className='CharacterCarousel__Carousel'>
                {slideIndex !== 0 && (<div className='CharacterCarousel__Slider__Blur Blur-left' />)}
                {characterImages.length === 0 ? (
                    <Loading />
                ) : (
                    <Slider className='CharacterCarousel__Slider' {...settings} ref={sliderRef}>
                        {characterImages.map((character, idx) => (
                            <div className='CharacterCarousel__CarouselCard' key={idx}>
                                <CarouselCard
                                    character={character}
                                />
                            </div>
                        ))}
                    </Slider>
                )}
                <div className='CharacterCarousel__Slider__Blur Blur-right' />
            </div>
            <div className='CharacterCarousel__Buttons flex'>
                <div>
                    {slideIndex !== 0 && (
                        <>
                            <Button
                                className='CharacterCarousel__Slider__Start CharacterCarousel__Button'
                                icon='skip-back'
                                size='small'
                                onClick={handleStart}
                            />
                            <Button
                                className='CharacterCarousel__Slider__Prev CharacterCarousel__Button'
                                icon='small-caret-left'
                                size='small'
                                onClick={handlePrevious}
                            />
                            <Caption className='CharacterCarousel__Slider__Text'>Prev</Caption>
                        </>
                    )}
                </div>
                <div>

                    <Caption className='CharacterCarousel__Slider__Text'>Next</Caption>
                    <Button
                        className='CharacterCarousel__Slider__Next CharacterCarousel__Button'
                        icon='small-caret-right'
                        size='small'
                        onClick={handleNext}
                    />
                    <Button
                        className='CharacterCarousel__Slider__End CharacterCarousel__Button'
                        icon='skip-forward'
                        size='small'
                        onClick={handleEnd}
                    />
                </div>
            </div>
        </>
    );
};

export default CharacterCarousel;
