import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SearchContext } from '../pages/Search';
import close from '../../images/comic_close.svg';
import openArrow from '../../images/comic_open.svg';
import { Overline } from '../modules/Typography';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';
import { UserContext } from '../../contexts/UserContextProvider';
import Tooltip from '../modules/Tooltip';

const ComicDrawer = props => {
    const context = props.collection ? CollectionsGalleryContext : SearchContext;
    const { apiUrl } = useContext(UserContext);
    const { comics } = useContext(context);
    const [open, setOpen] = useState(true);

    const handleComic = (title) => {
        // addFilter({ key: 'series', value: { text: title } });
    };

    return open ? (
        <div className='ComicDrawer'>
            <Overline block className='ComicDrawer__Title'>Comics</Overline>
            <div className='flex column align-center'>
                {Object.values(comics)
                    .sort((a, b) => {
                        if (a.total < b.total) {
                            return 1;
                        }
                        return -1;
                    })
                    .map((comic, idx) => (
                        <div className='ComicDrawer__Comic flex column align-flex-start' key={`comic-${idx}`} onClick={() => handleComic(comic.title)}>
                            <div className='flex column align-center' key={`comic-${idx}`}>
                                <Tooltip text={comic.title}>
                                    <img className='ComicDrawer__Comic__Poster' src={`${apiUrl}/image${comic.cover_thumbnail_path}`} />
                                </Tooltip>
                                <div className='ComicDrawer__Comic__Shelf'>
                                    <div className='ComicDrawer__Comic__Shelf__left' />
                                    <div className='ComicDrawer__Comic__Shelf__right' />
                                </div>
                            </div>
                            <Overline>{comic.total} IMAGE{comic.total !== 1 ? 'S' : ''}</Overline>
                        </div>
                    ))}
            </div>
            <img className='ComicDrawer__Close' src={close} onClick={() => setOpen(false)} />
        </div>

    ) : (
        <img className='ComicDrawer__Open' src={openArrow} onClick={() => setOpen(true)} />
    );
};

export default ComicDrawer;

ComicDrawer.propTypes = {
    // Whether or not a collection gallery need a comic drawer
    collection: PropTypes.bool
};

ComicDrawer.defaultProps = {
    collection: false
};
