import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../modules/IconButton';
import Icon from '../modules/Icon';
import Paper from '../modules/Paper';
import { Body } from '../modules/Typography';
// import TabBar from '../modules/TabBar';
// import TextField from '../modules/TextField';
import Dropzone from '../modules/Dropzone';
import { UserContext } from '../../contexts/UserContextProvider';
import fetch from '../../utils/fetch';
import { DataContext } from '../../contexts/DataContextProvider';

const ImageUploadModal = props => {
    const { user } = useContext(UserContext);
    const { setImagePaths, setUpload } = useContext(DataContext);
    // const [selectedTab, setSelectedTab] = useState('UPLOAD IMAGE');
    // const [url, setUrl] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    // const handleSearch = () => {
    //     console.log('url', url);
    // };

    const handleFiles = files => {
        setFile(files[0]);
    };

    const handleUpload = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('userId', user.id);
        formData.append('file', file);
        const image = await fetch('/upload', {
            method: 'POST',
            body: formData
        });
        setLoading(false);
        setImagePaths([{ image_path: image.image_path, region: null }]);
        setUpload(true);
        props.handleClose();
    };

    return (
        <div className='ImageModal'>
            <Paper>
                <div className='ImageModal__Top'>
                    <div className='flex'>
                        <div className='flex'>
                            <IconButton name='close' onClick={props.handleClose} />
                            <Body number={2}>CLOSE</Body>
                        </div>
                        <div className='ImageModal__Icons ImageModal__Icons__Group flex align-center'>
                            <Body number={2}>SEARCH BY IMAGE</Body>
                            <Icon className='ImageModal__Icon' name='photo' />
                            <Icon className='ImageModal__Icon' name='info' />
                            <Icon className='ImageModal__Icon' name='search' />
                        </div>
                    </div>
                    {/* <TabBar
                        tabs={['UPLOAD IMAGE', 'IMAGE URL']}
                        selectedTab={selectedTab}
                        updateTab={(tab) => setSelectedTab(tab)}
                    /> */}
                </div>
                {/* {selectedTab === 'IMAGE URL' ? (
                    <div className='ImageModal__Bottom'>
                        <Heading number={6}>URL to an image</Heading>
                        <TextField
                            className='ImageModal__TextField'
                            styleType='white'
                            placeholder='http://www.url.com/image.png'
                            value={url}
                            onChange={e => setUrl(e.target.value)}
                            onSubmit={handleSearch}
                        />
                        <Body number={2} block className='center-text'>We'll download the image for you.</Body>
                    </div>
                ) : ( */}
                <div>
                    <Dropzone
                        className='ImageModal__Dropzone'
                        handleFiles={handleFiles}
                        handleCancel={props.handleClose}
                        file={file}
                        resetFile={() => setFile(null)}
                        handleUpload={handleUpload}
                        loading={loading}
                        confirmText='CONFIRM AND SEARCH SIMILAR IMAGE'
                    />
                    <Body number={2} block className='ImageModal__Footer center-text'>Data protection is important! No data is sent. The magic happens in your browser.</Body>
                </div>
                {/* )} */}
            </Paper>
        </div>
    );
};

export default ImageUploadModal;

ImageUploadModal.propTypes = {
    handleClose: PropTypes.func
};
