import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Login from '../components/pages/Login';
import Loading from '../components/modules/Loading';
import fetch from '../utils/fetch';

export const UserContext = React.createContext();

const UserComponent = ({ children }) => {
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [fetchedUser, setFetched] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [user, setUser] = useState({
        id: '',
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        active: false
    });

    const fetchUsers = async () => {
        const response = await fetch('/allUsers');
        const sortedResponse = response.sort((x, y) => {
            if (x.first_name < y.first_name) return -1;
            if (x.first_name > y.first_name) return 1;
            return 0;
        });
        setAllUsers(sortedResponse);
    };

    useEffect(() => {
        const getConfig = async () => {
            const { imagePath } = await fetch('/config');
            setApiUrl(imagePath);
        };

        const login = async () => {
            const cookies = new Cookies();

            try {
                const response = await fetch('/user/login', {
                    method: 'POST',
                    body: {}
                });
                if (response.active) {
                    setUser({
                        id: response.id,
                        role: response.role,
                        email: response.email,
                        firstName: response.first_name,
                        lastName: response.last_name,
                        active: response.active
                    });
                    cookies.set('user_id', response.id);
                }
                setFetched(true);
            } catch (err) {
                cookies.remove('access_token');
                cookies.remove('id_token');
                cookies.remove('user_id');
                console.log('err', err);
                setFetched(true);
            }
        };
        getConfig();
        login();
        fetchUsers();
    }, []);

    let content = <Loading />;
    if (fetchedUser) {
        content = <Login />;
        if (user.id || location.pathname === '/reset-password') {
            content = children;
        }
    }

    return (
        <UserContext.Provider value={{
            user,
            allUsers,
            setUser,
            apiUrl,
            refetch: fetchUsers
        }}
        >
            {content}
        </UserContext.Provider>
    );
};

UserComponent.propTypes = {
    children: PropTypes.object
};

export default UserComponent;
