import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import MenuSurface from './MenuSurface';
import Checkbox from './Checkbox';
import { Caption } from './Typography';
import SaveMenu from '../search/SaveMenu';
import { SearchContext } from '../pages/Search';
import { UserContext } from '../../contexts/UserContextProvider';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';

import magnifyingGlass from '../../images/ico_mag_glass.svg';

const GridImage = props => {
    const { image, setBgOn, isBgOn } = props;
    const { size, thumbnail_path: thumb, id } = image;
    const { apiUrl } = useContext(UserContext);
    const { selected, allSelected, setSelected } = props.hasCheckbox ? useContext(SearchContext) : {};
    const { handleDelete } = !props.hasCheckbox ? useContext(CollectionsGalleryContext) : {};
    const [loaded, setLoaded] = useState(false);

    const [saveOpen, setSaveOpen] = useState(false);
    const isLoading = thumb === '#';

    const handleSave = () => {
        setBgOn(!isBgOn);
        setSaveOpen(!saveOpen);
    };

    const height = size.h * 305 / size.w;

    const handleDownload = async () => {
        window.open(`${apiUrl}/image${image.path}`);
    };

    const handleImageLoad = () => {
        setLoaded(true);
    };

    let options = [
        { text: 'SAVE TO', onClick: handleSave },
        { text: 'DELETE', onClick: () => handleDelete(id) },
        { text: 'DOWNLOAD', onClick: handleDownload }
        // { text: 'SHARE' }
    ];

    if (props.hasCheckbox) options = options.filter(opt => opt.text !== 'DELETE');

    const imagePath = isLoading ? '#' : `${apiUrl}/image${thumb || ''}`;
    return (
        <div
            className={`ImageGrid__item ${isLoading || !loaded ? 'ImageGrid__item--loading' : ''}`}
            style={{
                height: `${height}px`
            }}
        >

            {!isLoading && <img src={imagePath} onLoad={handleImageLoad} />}
            {props.hasCheckbox && (
                <Checkbox
                    className='ImageGrid__item__checkbox'
                    checked={allSelected || selected[image.id]}
                    value={image.id}
                    handleCheck={({ checked }) => setSelected(selected => ({ ...selected, [image.id]: checked }))}
                />
            )}
            {props.ccOn && (
                <div className='ImageGrid__item__CC'>
                    <Caption
                        className={`ImageGrid__item__CC__text ImageGrid__item__CC__text--${height < 90 ? 'small' : 'regular'}`}
                    >
                        {image.text}
                    </Caption>
                </div>
            )}
            <div className='ImageGrid__item__overlay'>
                <MenuSurface
                    options={options}
                    className='ImageGrid__item__menu MenuSurface--striped'
                >
                    <Icon name='ellipse-b' className='ImageGrid__item__icon Icon__circle pointer' />
                </MenuSurface>
                <div style={{ width: '100%', height: '100%' }} onClick={() => props.openDetails(image)}>
                    <img src={magnifyingGlass} className='center' />
                </div>
            </div>
            {saveOpen && (
                <>
                    <div className='DialogWrapper__background' />
                    <SaveMenu handleClose={handleSave} imageId={image.id} imagePath={imagePath} setSaveOpen={setSaveOpen} />
                </>
            )}
        </div>
    );
};

export default GridImage;

GridImage.propTypes = {
    image: PropTypes.object,
    setBgOn: PropTypes.func,
    isBgOn: PropTypes.bool,
    hasCheckbox: PropTypes.bool,
    ccOn: PropTypes.bool,
    openDetails: PropTypes.func
};

GridImage.defaultProps = {
    hasCheckbox: true
};
