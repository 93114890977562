import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Camera,
    BackArrow,
    Checkbox,
    CheckboxOn,
    ExpandArrow,
    Filter,
    Help,
    HelpOn,
    Search,
    SearchOn
} from './icons';

const imports = [
    'backArrow',
    'camera',
    'expandArrow',
    'filter',
    'helpOn',
    'help',
    'searchOn',
    'search',
    'checkbox',
    'checkboxOn'
];

const Icon = ({ className, name, faded, size, ...props }) => {
    const [fill, setFill] = useState('transparent');
    const iconRef = useRef(null);

    // match svg colors
    useEffect(() => {
        if (imports.includes(name) && iconRef.current) {
            const iconStyles = window.getComputedStyle(iconRef.current);
            setFill(iconStyles.getPropertyValue('color'));
        }
    }, [name]);

    let component = cleanBuggyNames(name);
    if (imports.includes(name)) {
        switch (name) {
        case 'backArrow':
            component = <BackArrow fill={fill} />;
            break;
        case 'camera':
            component = <Camera fill={fill} />;
            break;
        case 'filter':
            component = <Filter fill={fill} />;
            break;
        case 'expandArrow':
            component = <ExpandArrow fill={fill} />;
            break;
        case 'helpOn':
            component = <HelpOn fill={fill} />;
            break;
        case 'help':
            component = <Help fill={fill} />;
            break;
        case 'searchOn':
            component = <SearchOn fill={fill} />;
            break;
        case 'search':
            component = <Search fill={fill} />;
            break;
        case 'checkbox':
            component = <Checkbox fill={fill} />;
            break;
        case 'checkboxOn':
            component = <CheckboxOn fill={fill} />;
            break;
        }
    }

    return (
        <span
            className={`Icon ${className} ${faded ? 'Icon--faded' : ''} Icon--${size}`}
            aria-hidden='true'
            ref={iconRef}
            {...props}
        >
            {component}
        </span>
    );
};

export default Icon;

Icon.propTypes = {
    // className to pass onto tags
    className: PropTypes.string,

    // name of the icon
    name: PropTypes.string,

    // whether or not the icon is faded
    faded: PropTypes.bool,

    // size of the icon
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

Icon.defaultProps = {
    className: '',
    faded: false,
    size: 'medium'
};

const cleanBuggyNames = (name) => {
    switch (name) {
    case 'trash-can':
        return '';
    case 'arrow-std-left':
        return '';
    case 'map-a':
        return '';
    case 'ellipse-b':
        return '';
    case 'area-graph':
        return '';
    case 'small-caret-down':
        return '';
    case 'small-caret-up':
        return '';
    default:
        return name;
    }
};
