import Cookies from 'universal-cookie';
const cookies = new Cookies();

const fetch = async (url, options = {}) => {
    const headers = options.headers || {};

    if ((options.data || options.body) && !url.includes('upload')) {
        headers['content-type'] = 'application/json';
        if (typeof options.body === 'object') {
            options.body = JSON.stringify(options.body);
        }
    }
    options.headers = headers;
    options.credentials = 'same-origin';
    const makeRequest = async () => {
        const response = await window.fetch(url, options);
        return response;
    };
    const response = await makeRequest();
    if (response.status === 401 && window.location.pathname !== '/') {
        cookies.remove('access_token');
        cookies.remove('id_token');
        cookies.remove('user_id');
        window.location.reload();
    } else {
        let json;
        if (headers.accept === 'text/csv') {
            json = await response.text();
        } else if (headers.accept === 'application/xml') {
            json = response.text();
        } else if (headers.accept === 'file/xml' || headers.accept === 'application/zip') {
            json = await response.blob();
            // } else if ((response.type === 'cors' || response.status === 204) && !url.includes('kanvas')) {
            //     json = response;
        } else {
            json = await response.json();
        }
        return json;
    }
};

export default fetch;
