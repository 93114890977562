import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ImageGridContext } from './ImageGrid';
import Checkbox from './Checkbox';
import { Body } from './Typography';
import Switch from './Switch';
import Select from './Select';
import List from './List';
import { SearchContext } from '../pages/Search';
import BaseAccordion from './BaseAccordion';

const SearchImageHeader = props => {
    const { closeCaptioned, setCloseCaptioned } = useContext(ImageGridContext);
    const { allSelected, setAllSelected, handleCheck, handleFilter, handleSearchMore, setSort, sortBy } = useContext(SearchContext);
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(prev => !prev);

    const handleClick = useCallback((e) => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    return (
        <div className='ImageGrid__Header flex align-flex-start'>
            <div className='flex flex-start align-center '>
                <Checkbox
                    className='ImageGrid__SelectAll'
                    checked={allSelected}
                    handleCheck={({ checked }) => setAllSelected(checked)}
                />
                <BaseAccordion
                    title='Select Action'
                    className='ImageGrid__SelectAction'
                    open={open}
                    toggle={toggle}
                >
                    <List
                        items={[
                            { name: 'Filter More Like this', value: 'filterMore', onSelect: handleFilter },
                            { name: 'New Search From Images', value: 'search', onSelect: handleSearchMore }
                            // { name: 'Share', value: 'share', onSelect: handleShare }

                        ]}
                        handleCheck={handleCheck}
                    />
                </BaseAccordion>
                <Body className='ImageGrid__CC'>CC</Body>
                <Switch
                    checked={closeCaptioned}
                    onChange={e => setCloseCaptioned(e.target.checked)}

                />
            </div>
            <Select
                placeholder='Sort By'
                className='ImageGrid__Select'
                bodyText
                styleType='dark'
                selectedValue={sortBy}
                options={[
                    { text: 'Best Match', value: 'Best Match' },
                    { text: 'Published Date', value: 'Publish Date' },
                    { text: 'Series', value: 'Series' }
                ]}
                onChange={({ value }) => setSort(value)}
            />
        </div>
    );
};

export default SearchImageHeader;
