import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useFlexLayout, useSortBy, useResizeColumns } from 'react-table';
import IconButton from './IconButton';
import Select from './Select';
import Loading from './Loading';
import { Caption } from './Typography';

const PaginatedTable = props => {
    const columns = useMemo(() => (props.columns), [props.columns]);

    const data = useMemo(() => (props.data), [props.data]);

    const options = {
        columns,
        data,
        initialState: {
            pageIndex: 0,
            sortBy: [
                { id: 'userStatus', desc: false }
            ]
        },
        manualPagination: props.pagination,
        // manualSortBy: true,
        pageCount: props.pageCount
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        options,
        useResizeColumns,
        useFlexLayout,
        useSortBy,
        usePagination
    );

    const handlePageSizeChanged = ({ value }) => {
        if (props.pagination) {
            props.setLimit(+value);
            props.setOffset(0);
        }
    };

    const pageRows = rows.slice(props.offset, props.limit + props.offset);

    return (
        <>
            {props.pagination && (
                <div className='Table__footer'>
                    <Caption className='Table__footer__pagination__text'>
                        Rows per page:{' '}
                    </Caption>
                    <Select
                        className='Table__footer__pagination__select'
                        placeholder={`${props.limit}`}
                        value={`${props.limit}`}
                        options={props.pageSizeOptions.map(pageSize => ({ text: pageSize, value: pageSize }))}
                        onChange={handlePageSizeChanged}
                    />
                    <Caption className='Table__footer__pagination__text'>
                        {props.offset + 1} - {(props.offset + props.limit < props.totalItems) ? (props.offset + props.limit) : props.totalItems} of {props.totalItems}
                    </Caption>
                    {/* <IconButton
                        dsm
                        styleProps={{ fontSize: '1.20rem' }}
                        name='first'
                        size='small'
                        className='Table__footer__pagination__first'
                        onClick={() => { props.setOffset(0); }}
                        disabled={props.offset === 0}
                    /> */}
                    <IconButton
                        name='caret-left'
                        size='small'
                        className='Table__footer__pagination__icon'
                        onClick={() => props.setOffset(props.offset - props.limit)}
                        disabled={props.offset === 0}
                    />
                    <IconButton
                        name='caret-right'
                        size='small'
                        className='Table__footer__pagination__icon'
                        onClick={() => props.setOffset(props.offset + props.limit)}
                        disabled={props.offset + props.limit >= props.totalItems}
                    />
                    {/* <IconButton
                        name='last'
                        dsm
                        styleProps={{ fontSize: '1.20rem' }}
                        size='small'
                        className='Table__footer__pagination__last'
                        onClick={() => { props.setOffset(lastPageOffset === props.totalItems ? props.totalItems - props.limit : lastPageOffset); }}
                        disabled={props.offset + props.limit >= props.totalItems}
                    /> */}
                </div>
            )}
            {props.loading ? (
                <>
                    <table {...getTableProps()} className='Table'>
                        <thead>
                            {headerGroups.map((headerGroup, idx) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${idx}`}>
                                    {headerGroup.headers.map((column, idx) => (
                                        <th
                                            key={`header-${idx}`}
                                            className='Typography Typography--caption'
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    </table>
                    <Loading />
                </>
            ) : (
                <table {...getTableProps()} className='Table'>
                    <thead>
                        {headerGroups.map((headerGroup, idx) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${idx}`}>
                                {headerGroup.headers.map((column, idx) => (
                                    <th
                                        key={`header-${idx}`}
                                        className='Typography Typography--caption'
                                        {...column.getHeaderProps(idx === 0 && column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}
                                        {idx === 0 && (
                                            <span className={`Table__Sort ${column.isSortedDesc ? 'Table__Sort--desc' : ''}`} />
                                        )}
                                        {column.canResize && (
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${
                                                    column.isResizing ? 'isResizing' : ''
                                                }`}
                                            />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {pageRows.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className={props.selectable ? 'pointer' : ''} key={`row-${idx}`} onClick={props.selectable && (() => props.handleRowClick(row))}>
                                    {row.cells.map((cell, idx) => {
                                        return (
                                            <td
                                                key={`cell-${idx}`}
                                                className={cell.column.className || 'Typography Typography--body1 capitalize'}
                                                {...cell.getCellProps()}

                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
            )}
        </>
    );
};

export default PaginatedTable;

PaginatedTable.propTypes = {
    // className to pass down to tags
    columns: PropTypes.array,
    // Data displayed in the table
    data: PropTypes.array,
    /* Function to handle clicks on rows */
    handleRowClick: PropTypes.func,

    /* If table is paginated */
    pagination: PropTypes.bool,

    /* The max number of rows the table should show */
    limit: PropTypes.number,
    setLimit: PropTypes.func,
    offset: PropTypes.number,
    setOffset: PropTypes.func,
    totalItems: PropTypes.number,
    pageCount: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    selectable: PropTypes.bool,
    /* If the data is loading */
    loading: PropTypes.bool
};

PaginatedTable.defaultProps = {
    selectable: false,
    className: '',
    hasBorder: true,
    loading: false,
    columns: [],
    data: [],
    pagination: true,
    pageSizeOptions: [],
    totalItems: 0
};
