import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle } from './Typography';

const UserCircle = props => {
    const initials = `${props.firstName?.[0] || ''}${props.lastName?.[0] || ''}`;
    return (
        <div className={`UserCircle__Circle ${props.className || ''} UserCircle--${props.style}`}>
            <Subtitle className='UserCircle__Subtitle' number={1}>{initials}</Subtitle>
        </div>
    );
};

export default UserCircle;

UserCircle.propTypes = {
    className: PropTypes.string,
    // Name of the member
    firstName: PropTypes.string,
    lastName: PropTypes.string,

    style: PropTypes.oneOf(['light', 'dark'])

};

UserCircle.defaultProps = {
    style: 'dark'
};
