import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AdminContext } from '../pages/Admin';
import { Caption, Heading, Subtitle } from '../modules/Typography';
import DialogWrapper from '../modules/DialogWrapper';
import IconButton from '../modules/IconButton';
import TextField from '../modules/TextField';
import ListItem from '../modules/ListItem';
import Button from '../modules/Button';
// import Radio from '../modules/Radio';
import Icon from '../modules/Icon';
import Box from '../modules/Box';

const UserAction = props => {
    const {
        user,
        role,
        roles,
        active,
        emailText,
        setEmailText,
        setActive,
        actionType,
        handleClose,
        createUser,
        updateUser,
        setRole
    } = useContext(AdminContext);

    const editAction = actionType === 'edit';

    return (
        <DialogWrapper className='UserAction' hideFooter handleCancel={handleClose} open={props.open}>
            <Box
                className={`UserAction__Box ${props.className ? props.className : ''}`}
                type='gray-filled'
                borderType='none'
                rounded={false}
                padded={false}
            >
                <div className='UserAction__Header flex'>
                    <Icon name='user-plus' size='large' />
                    <Heading className='UserAction__Header__Text' number={6}>{editAction ? 'Edit User' : 'User Setup'}</Heading>
                    <IconButton
                        className='UserAction__Header__Close'
                        name='close'
                        size='medium'
                        onClick={handleClose}
                    />
                </div>
                <div className='UserAction__Body flex column'>
                    {!editAction ? (
                        <div className='UserAction__Body__Section UserAction-details'>
                            <Subtitle className='UserAction__Body__Title' number={1} heavy>Assign role to this person:</Subtitle>
                            <div className='UserAction__Body__Email'>
                                <div className='flex'>
                                    <Caption number={1}>Company issued email*</Caption>
                                    <Caption className='UserAction__Body__Email--faded' number={1}>*required</Caption>
                                </div>
                                <TextField
                                    className='UserAction__Body__TextField'
                                    placeholder='name@disney.com'
                                    value={emailText}
                                    styleType='dark'
                                    onChange={e => setEmailText(e.target.value)}
                                    padded={false}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='UserAction__Body__Section UserAction-details'>
                            <div className='flex column'>
                                <Caption number={1} heavy>Company issued email*</Caption>
                                <Caption>{user?.email || 'EMAIL'}</Caption>
                            </div>
                            <div className='UserAction__Body__Section__Name flex flex-start'>
                                <div className='UserAction__Body__Section__Caption flex column'>
                                    <Caption number={1} heavy>First Name</Caption>
                                    <Caption>{user?.firstName || 'FIRSTNAME'}</Caption>
                                </div>
                                <div className='UserAction__Body__Section__Caption flex column'>
                                    <Caption number={1} heavy>Last Name</Caption>
                                    <Caption>{user?.lastName || 'LASTNAME'}</Caption>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='UserAction__Body__Section UserAction-role flex column'>
                        <Caption className='UserAction__Body__Title' number={1} heavy>Role</Caption>
                        {roles.map((userRole, idx) => (
                            <ListItem
                                key={idx}
                                className='UserAction__Body__Radio'
                                name={userRole.text}
                                value={userRole.value}
                                radioCheckbox
                                checked={role.toLowerCase() === userRole.value.toLowerCase()}
                                onSelect={() => setRole(userRole.value)}
                            />
                        ))}
                    </div>
                    <div className='UserAction__Body__Section UserAction-status flex column'>
                        <Caption className='UserAction__Body__Title' number={1} heavy>Status</Caption>
                        <ListItem
                            className='UserAction__Body__Radio'
                            name='Active'
                            value='active'
                            radioCheckbox
                            checked={active}
                            onSelect={() => setActive(true)}
                        />
                        <ListItem
                            className='UserAction__Body__Radio'
                            name='Blocked'
                            value='blocked'
                            radioCheckbox
                            checked={!active}
                            onSelect={() => setActive(false)}
                        />
                    </div>
                    {/* {!editAction && (
                        <div className=' UserAction__Body__Notification flex column'>
                            <Caption className='UserAction__Body__Title' number={1} heavy>Notification</Caption>
                            <Radio
                                className='UserAction__Body__Radio'
                                name='An email notification will be sent to this person'
                                value='notify'
                                selected={notify}
                                single
                                padded={false}
                                handleClick={() => setNotify(notify => !notify)}
                            />
                        </div>
                    )} */}
                    <div className='UserAction__Body__Buttons flex'>
                        <Button
                            className='UserAction_Body-cancel UserAction__Body__Button'
                            size='regular'
                            text='CANCEL'
                            type='secondary'
                            onClick={handleClose}
                        />
                        <Button
                            className='UserAction_Body-done UserAction__Body__Button'
                            text={editAction ? 'UPDATE' : 'DONE'}
                            size='regular'
                            type='primary'
                            onClick={editAction ? updateUser : createUser}
                        />
                    </div>
                </div>
            </Box>
        </DialogWrapper>
    );
};

export default UserAction;

UserAction.propTypes = {
    // className passed down
    className: PropTypes.string,

    // is component is open/visible
    open: PropTypes.bool
};
