import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ fill }) => (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>Rectangle Copy 3</title>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search-Result-Copy-2' transform='translate(-236.000000, -499.000000)' fill='#ED1C24' stroke='#070707' strokeWidth='3'>
                <rect id='Rectangle-Copy-3' x='237.5' y='500.5' width='17' height='17' />
            </g>
        </g>
    </svg>
);

Checkbox.propTypes = {
    fill: PropTypes.string
};

export default Checkbox;
