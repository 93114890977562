import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import Icon from '../modules/Icon';
import Loading from '../modules/Loading';
import { Caption } from '../modules/Typography';

const CollectionsMenu = props => {
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setSaving(false);
    }, [props.collections]);

    const handleSave = async (collectionId) => {
        setSaving(true);
        await fetch(`/addImageToCollection/${collectionId}`, {
            method: 'POST',
            body: {
                imageIds: [props.imageId]
            }
        });
        props.fetchCollections();
    };

    const filteredCollections = (props.searchTerm
        ? props.collections.filter(collection => (
            collection.name.toLowerCase().includes(props.searchTerm.toLowerCase())
        //    || collection.subcollections.find(sub => sub.name.toLowerCase().includes(props.searchTerm.toLowerCase()))
        ))
        : props.collections)
        .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 1;
        });

    return (
        <div className='CollectionsMenu'>
            {filteredCollections.map(collection => (
                <div className='CollectionsMenu__Item' key={collection.id}>
                    <div className='CollectionsMenu__Item__Container flex'>
                        <div>
                            {/* {collection.subcollections.length ? (
                                <Icon name='small-caret-down' className='CollectionsMenu__Item__redText CollectionsMenu__Item__caret' />
                            ) : ''} */}
                            <Caption className='CollectionsMenu__Item__Text'>{collection.name.toUpperCase()}</Caption>
                        </div>
                        <div className='CollectionsMenu__Item__Save flex space-around' onClick={() => handleSave(collection.id)}>
                            {saving ? (
                                <div className='CollectionsMenu__Loading'>
                                    <Loading />
                                </div>

                            ) : (
                                <div className='flex'>
                                    <Icon name={collection.comic_images.find(image => image.image_id === props.imageId) ? 'check-circle' : 'bookmark'} className='active-text' />
                                    <Caption className='ImageGrid__item__SaveMenu__redText'>
                                        {collection.comic_images.find(image => image.image_id === props.imageId) ? 'SAVED' : 'SAVE'}
                                    </Caption>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* {collection.subcollections
                        .filter(sub => (sub.name.toLowerCase().includes(props.searchTerm.toLowerCase())))
                        .map(sub => (
                            <div className='CollectionsMenu__Item__Container' key={sub.id}>
                                <div className='CollectionsMenu__Item__SubCollection flex'>
                                    <Caption className='CollectionsMenu__Item__Text'>- {sub.name.toUpperCase()}</Caption>
                                    <div className='CollectionsMenu__Item__Save flex space-around' onClick={() => handleSave(sub.id)}>
                                        {saving ? (
                                            <div className='CollectionsMenu__Loading'>
                                                <Loading />
                                            </div>

                                        ) : (

                                            <div>
                                                <Icon name={sub.comic_images.find(image => image.image_id === props.imageId) ? 'check-circle' : 'bookmark'} className='active-text' />
                                                <Caption className='ImageGrid__item__SaveMenu__redText'>
                                                    {sub.comic_images.find(image => image.image_id === props.imageId) ? 'SAVED' : 'SAVE'}
                                                </Caption>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))} */}
                </div>
            ))}
        </div>
    );
};

export default CollectionsMenu;

CollectionsMenu.propTypes = {
    imageId: PropTypes.string,
    searchTerm: PropTypes.string,
    fetchCollections: PropTypes.func,
    collections: PropTypes.arrayOf(PropTypes.object)
};
