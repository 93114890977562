import React from 'react';
import PropTypes from 'prop-types';

const Camera = ({ fill }) => (
    <svg width='26px' height='23px' viewBox='0 0 26 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>icons8-vintage-camera</title>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.730158942'>
            <g id='Search-Result-Copy-2' transform='translate(-528.000000, -498.000000)' fillRule='nonzero'>
                <g id='icons8-vintage-camera' style={{ mixBlendMode: 'luminosity' }} transform='translate(529.000000, 498.000000)'>
                    <path d='M5.79310345,3.66666667 L2.48275862,3.66666667 L2.48275862,2.56618939 C2.48275862,2.13888889 2.75862069,1.83333333 3.14439669,1.83333333 L5.13146538,1.83333333 C5.51724138,1.83333333 5.79310345,2.13888889 5.79310345,2.56618939 L5.79310345,3.66666667 Z' id='Path' fill='#FFFFFF' />
                    <path d='M21.6,22 L2.4,22 C1.0804686,22 0,20.8078163 0,19.3518519 L0,10.0833333 L24,10.0833333 L24,19.3518519 C24,20.8078163 22.9195314,22 21.6,22 Z' id='Path' stroke='#000000' fill='#FFFFFF' />
                    <path d='M5.39951524,10.0833333 C5.1522089,10.9776203 4.96551724,11.8719072 4.96551724,12.8333333 C4.96551724,17.3719072 8.31627186,21.0833333 12.4137931,21.0833333 C16.5113143,21.0833333 19.862069,17.3719072 19.862069,12.8333333 C19.862069,11.8719072 19.6753773,10.9776203 19.428071,10.0833333 L5.39951524,10.0833333 Z' id='Path' fill='#CFCFCF' />
                    <path d='M2.4,3.66666667 L21.6,3.66666667 C22.9195314,3.66666667 24,4.82216781 24,6.23333333 L24,10.0833333 L0,10.0833333 L0,6.23333333 C0,4.82216781 1.0804686,3.66666667 2.4,3.66666667 Z' id='Path' stroke='#000000' fill='#FFFFFF' />
                    <path d='M18.2068966,3.66666667 L6.62068966,3.66666667 L8.6217969,0.549045444 C8.85614046,0.181424222 9.20995255,0 9.62120215,0 L15.2661183,0 C15.6796647,0 16.03118,0.181424222 16.2678203,0.549045444 L18.2068966,3.66666667 Z' id='Path' fill='#FFFFFF' />
                    <path d='M19.0344828,10.0833333 C18.0961896,6.85262027 15.459814,4.58333333 12.4137931,4.58333333 C9.36777217,4.58333333 6.73139656,6.85262027 5.79310345,10.0833333 L19.0344828,10.0833333 Z' id='Path' fill='#455A64' />
                    <path d='M12.4137931,6.41666667 C9.21551724,6.41666667 6.62068966,9.29079861 6.62068966,12.8333333 C6.62068966,16.3758681 9.21551724,19.25 12.4137931,19.25 C15.612069,19.25 18.2068966,16.3758681 18.2068966,12.8333333 C18.2068966,9.29079861 15.612069,6.41666667 12.4137931,6.41666667 Z' id='Path' fill='#000000' />
                    <path d='M15.5062015,9.64735841 C14.7329291,8.75880702 13.5717615,8.25 12.4105939,8.25 C11.2494263,8.25 10.0907767,8.75880702 9.3149863,9.64735841 C8.99257963,9.96505265 9.05806816,10.4738597 9.38047483,10.7915539 C9.70288149,11.1092481 10.2192358,11.0447168 10.5416424,10.7270226 C11.5088624,9.64735841 13.3148433,9.64735841 14.2820633,10.7270226 C14.4734929,10.9181354 14.6674405,10.9826667 14.9268767,10.9826667 C15.1183063,10.9826667 15.3122539,10.9181354 15.5062015,10.7915539 C15.7631196,10.5359098 15.8286081,9.96505265 15.5062015,9.64735841 Z' id='Path' fill='#5B5B5B' />
                </g>
            </g>
        </g>
    </svg>
);

Camera.propTypes = {
    fill: PropTypes.string
};

export default Camera;
