import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import DialogWrapper from './DialogWrapper';
import IconButton from './IconButton';
import { Subtitle } from './Typography';
import { UserContext } from '../../contexts/UserContextProvider';
import TextField from './TextField';

const renameCollection = async (id, name) => {
    await fetch(`/usercollections/${id}`, {
        method: 'PUT',
        body: {
            name
        }
    });
};

const deleteCollection = async (id) => {
    await fetch(`/usercollections/${id}`, {
        method: 'DELETE'
    });
};

const createCollection = async (id, name, ownerId) => {
    await fetch('/usercollections', {
        method: 'POST',
        body: {
            name,
            parentId: null,
            ownerId
        }
    });
};

const duplicateCollection = async (id, name) => {
    await fetch('/usercollections/duplicate', {
        method: 'POST',
        body: {
            id,
            name
        }
    });
};

const modals = {
    rename: {
        title: 'Rename Collection',
        confirmFunc: renameCollection
    },
    delete: {
        title: 'Delete Collection',
        confirmFunc: deleteCollection,
        confirmText: 'Confirm'
    },
    download: {
        title: 'Download Collection'
    },
    duplicate: {
        title: 'Duplicate Collection',
        confirmFunc: duplicateCollection
    },
    new: {
        title: 'New Collection',
        confirmFunc: createCollection,
        confirmText: 'Create'
    }
};

const ActionModal = props => {
    const { user } = useContext(UserContext);
    const modal = modals[props.modalType] || {};
    const [name, setName] = useState('');

    useEffect(() => {
        if (props.collection) {
            if (props.modalType === 'duplicate') {
                setName(`${props.collection.name} copy`);
            } else {
                setName(props.collection.name);
            }
        } else {
            setName('');
        }
    }, [props.collection, props.modalType]);

    const handleConfirm = async () => {
        const id = props.collection ? props.collection.id : null;
        await modal.confirmFunc(id, name, user.id);
        props.onConfirm();
        props.handleCancel();
    };

    return (
        <DialogWrapper
            open={props.open}
            confirmText={modal.confirmText || 'Save'}
            handleConfirm={handleConfirm}
            handleCancel={props.handleCancel}
            className='ActionModal'
        >
            <div className='flex'>
                <Subtitle>{modal.title}</Subtitle>
                <IconButton className='ActionModal__Close' name='close' onClick={props.handleCancel} />
            </div>
            <Subtitle className='ActionModal__Subtitle' number={2}>Name</Subtitle>
            {props.modalType === 'delete' ? (
                <Subtitle>Once this collection is deleted, it is rendered to dust, making it unrecoverable. Are you sure?</Subtitle>
            ) : (
                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onSubmit={handleConfirm}
                />
            )}

        </DialogWrapper>
    );
};

export default ActionModal;

ActionModal.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    collection: PropTypes.object,
    modalType: PropTypes.string,
    onConfirm: PropTypes.func
};
