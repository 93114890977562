import React, { createRef, useCallback, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import moment from 'moment';
import List from './List';
import IconButton from './IconButton';
import Box from './Box';
import TabBar from './TabBar';
import TextArea from './TextArea';
import ListItem from './ListItem';
import { UserContext } from '../../contexts/UserContextProvider';
import avengersImage from '../../images/ico_avengers.svg';
import vehicleImage from '../../images/ico_vehicle.svg';
import textImage from '../../images/ico_text.svg';
import noteImage from '../../images/ico_note.svg';
import penImage from '../../images/ico_pen.svg';
import locationImage from '../../images/ico_locations.svg';
import targetImage from '../../images/ico_target.svg';
import Button from './Button';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';

const ImageDetail = ({ image, ...props }) => {
    const { apiUrl } = useContext(UserContext);
    const { getCollection } = props.collectionName ? useContext(CollectionsGalleryContext) : {};
    const imageDetailRef = createRef();
    const [selectedTab, setSelectedTab] = useState('Image Detail');
    const [note, setNote] = useState(image.userNote || '');
    const { comic_info: { title, creators, published_date: publishedDate } = {}, detections } = image;
    const imageView = selectedTab === 'Image Detail';

    useEffect(() => {
        setNote(image.userNote || '');
    }, [image]);

    const handleNotes = async () => {
        await fetch(`/usercollections/note/${props.collectionId}`, {
            method: 'POST',
            body: {
                imageId: image.id,
                note
            }
        });
        getCollection();
    };

    const imageDetections = detections?.reduce((prev, curr) => {
        const type = curr.detection_class;
        if (!prev[type]) {
            prev[type] = [];
        }
        if (!prev[type].includes(curr.label)) {
            prev[type].push(curr.label);
        }
        return prev;
    }, {});

    const imageDetailItems = [
        {
            name: 'CHARACTER(S)',
            subtitle: imageDetections.characters ? (
                <div className='ImageDetail__Roles'>
                    {imageDetections.characters.map((detection, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={detection}
                        />
                    ))}
                </div>
            ) : 'N/A',
            image: avengersImage
        },
        {
            name: 'LOCATION(S)',
            subtitle: imageDetections.locations ? (
                <div className='ImageDetail__Roles'>
                    {imageDetections.locations.map((detection, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={detection}
                        />
                    ))}
                </div>
            ) : 'N/A',
            image: locationImage
        },
        {
            name: 'KEY OBJECT(S)',
            subtitle: imageDetections.keyObjects ? (
                <div className='ImageDetail__Roles'>
                    {imageDetections.keyObjects.map((detection, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={detection}
                        />
                    ))}
                </div>
            ) : 'N/A',
            image: targetImage
        },
        {
            name: 'VEHICLE(S)',
            subtitle: imageDetections.vehicles ? (
                <div className='ImageDetail__Roles'>
                    {imageDetections.vehicles.map((detection, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={detection}
                        />
                    ))}
                </div>
            ) : 'N/A',
            image: vehicleImage
        },
        {
            name: 'SUPER POWER(S)',
            subtitle: imageDetections.superPowers ? (
                <div className='ImageDetail__Roles'>
                    {imageDetections.superPowers.map((detection, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={detection}
                        />
                    ))}
                </div>
            ) : 'N/A',
            icon: 'zap'
        }
    ];

    // IF WE'RE IN A COLLECTION, USER SHOULD SEE THE IMAGE NOTES
    if (props.collectionName) {
        const notes = {
            name: `NOTES FOR ${props.collectionName.toUpperCase()}`,
            subtitle: (
                <>
                    <TextArea
                        className='ImageDetail__TextArea'
                        value={note}
                        onChange={e => setNote(e.target.value)}
                    />
                    <Button
                        className='ImageDetail__Footer'
                        onClick={handleNotes}
                        text='Upload'
                        type='primary'
                    />
                </>
            ),
            image: noteImage
        };
        imageDetailItems.push(notes);
    }

    const comicDetailItems = [
        {
            name: 'TEXT/CAPTION',
            subtitle: image?.text || 'N/A',
            image: textImage
        },
        {
            name: 'CREATORS',
            subtitle: creators.length ? (
                <div className='ImageDetail__Roles'>
                    {creators.map((person, idx) => (
                        <ListItem
                            className='ImageDetail__Roles all-capitalize'
                            key={idx}
                            name={`${person.role.toUpperCase()}: ${person.name}`}
                        />
                    ))}
                </div>
            ) : 'N/A',
            image: penImage
        },
        {
            name: 'COMIC ORIGIN',
            subtitle: title || 'N/A',
            icon: 'clock'
        },
        {
            name: 'PAGE',
            subtitle: image?.page_info?.number || 'N/A',
            icon: 'page'
        },
        {
            name: 'PUBLISHED DATE',
            subtitle: publishedDate.length ? moment(publishedDate).format('LL') : 'N/A',
            icon: 'calendar'
        },
        {
            name: 'SOURCE(S)',
            subtitle: '5TH KIND',
            image: targetImage
        }
    ];

    // handle close
    const handleClick = useCallback((e) => {
        if (imageDetailRef.current) { console.log(e.target); }
        if (imageDetailRef.current && !imageDetailRef.current.contains(e.target)) {
            props.handleClose();
        }
    }, []);

    useEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    return (
        <Box
            className={`ImageDetail ${props.className ? props.className : ''}`}
            type='gray-filled'
            borderType='thin-dashed'
            rounded={false}
            ref={imageDetailRef}
        >
            <div className='ImageDetail__Header flex'>
                <IconButton
                    name='close'
                    size='small'
                    onClick={() => props.handleClose()}
                />
            </div>
            <div className='ImageDetail__Image'>
                <img className='ImageDetail__Image__Poster' src={`${apiUrl}/image${image.thumbnail_path}`} />
                <div className='ImageDetail__Image__Shelf'>
                    <div className='ImageDetail__Image__Shelf__left' />
                    <div className='ImageDetail__Image__Shelf__right' />
                </div>
            </div>
            <div className='ImageDetail__Tabbar'>
                <TabBar tabs={['Image Detail', 'Comic Detail']} selectedTab={selectedTab} updateTab={tab => setSelectedTab(tab)} />
            </div>
            <List
                className={`ImageDetail__List ${!imageView && 'ImageDetail__List-comic'}`}
                items={imageView ? imageDetailItems : comicDetailItems}
            />
        </Box>
    );
};

export default ImageDetail;

ImageDetail.propTypes = {
    className: PropTypes.string,
    image: PropTypes.object,

    handleClose: PropTypes.func.isRequired,
    collectionId: PropTypes.string,
    collectionName: PropTypes.string
};
