import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '../modules/TextField';
import Button from './Button';
import { Subtitle } from './Typography';
import IconButton from '../modules/IconButton';
import Select from './Select';
import { ImageGridContext } from './ImageGrid';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';

const GalleryImageHeader = props => {
    const { zoom, searchText, setZoom, setExpand, setSearchText } = useContext(ImageGridContext);
    const { sortBy, setSort } = useContext(CollectionsGalleryContext);

    const handleReset = () => {
        setZoom(100);
        setSearchText('');
        setExpand(false);
    };

    return (
        <div className={`GalleryImageHeader ${props.className ? `GalleryImageHeader-${props.className}` : ''} `}>
            <div className='GalleryImageHeader__Header__left flex'>
                <div className='GalleryImageHeader__Header__Zoom flex'>
                    <IconButton
                        name='minus'
                        size='small'
                        disabled={zoom === 0}
                        onClick={() => setZoom(zoom => Math.max(zoom - 10, 0))}
                    />
                    <div className='GalleryImageHeader__Header__Zoom--center'>
                        <Subtitle>{`${zoom}%`}</Subtitle>
                    </div>

                    <IconButton
                        name='plus'
                        size='small'
                        onClick={() => setZoom(zoom => zoom + 10)}
                    />
                </div>
                <Button
                    className='GalleryImageHeader__Header__Button'
                    onClick={handleReset}
                    text='Reset'
                    size='small'
                    collapseOnSmall
                    trailingIcon='refresh-ccw'
                />
                <TextField
                    className='GalleryImageHeader__Header__Textfield'
                    small
                    placeholder='SEARCH NAME'
                    icon='search'
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    // onSubmit={handleSearch}
                    styleType='dark'
                    padded={false}
                />
            </div>
            <div className='GalleryImageHeader__Header__right'>
                <Select
                    placeholder='Sort'
                    className='GalleryImageHeader__Header__Select'
                    styleType='dark'
                    bodyText
                    selectedValue={sortBy}
                    options={[
                        { text: 'Best Match', value: 'Best Match' },
                        { text: 'Published Date', value: 'Publish Date' },
                        { text: 'Series', value: 'Series' }
                    ]}
                    onChange={({ value }) => setSort(value)}
                />
            </div>
        </div>
    );
};

export default GalleryImageHeader;

GalleryImageHeader.propTypes = {
    className: PropTypes.string
};
