import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../modules/Dropzone';
import TextArea from '../modules/TextArea';
import DialogFooter from '../modules/DialogFooter';
import Icon from '../modules/Icon';
import { Subtitle } from '../modules/Typography';
import { UserContext } from '../../contexts/UserContextProvider';
import { GroupContext } from './Group';
import TextField from '../modules/TextField';
import fetch from '../../utils/fetch';
import { EditContext } from '../pages/EditHomePage';

const EditGroupItem = props => {
    const { refetch, setDirty } = useContext(EditContext);
    const { user, apiUrl } = useContext(UserContext);
    const { groupId } = useContext(GroupContext);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [query, setQuery] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [thumbUrl, setThumbUrl] = useState('');
    const { item } = props;

    useEffect(() => {
        if (item) {
            setTitle(item.title);
            setDescription(item.subtitle);
            setQuery(item.searchQuery);
            setThumbUrl(item.thumbnailUrl);
        }
    }, [props.item]);

    const handleAdd = async () => {
        if (!groupId) {
            await props.createGroup();
        }
        const response = await fetch(`/groups/${groupId}/model`, {
            method: 'POST',
            body: {
                title,
                subtitle: description,
                searchQuery: query
            }
        });
        const model = response?.comic_models.find(mod => mod.title === title) || [];
        await handleUpload(model.id);
    };

    const handleUpdate = async () => {
        if (file) {
            await handleUpload(props.item.id);
        }
        await fetch(`/groupmodel/${item.id}`, {
            method: 'PUT',
            body: {
                title,
                subtitle: description,
                searchQuery: query
            }
        });
    };

    const handleCancel = () => {
        // setDirty(false);
    };

    const handleFiles = files => {
        setFile(files[0]);
        setDirty(true);
    };

    const handleUpload = async (modelId) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('userId', user.id);
        formData.append('file', file);
        const image = await fetch(`/groupmodel/uploadthumb/${modelId}`, {
            method: 'POST',
            body: formData
        });
        setLoading(false);
        return image;
    };

    const handleConfirm = async () => {
        if (!title || !description || !query || !(thumbUrl || file)) {
            return;
        }
        if (item) {
            await handleUpdate();
        } else {
            await handleAdd();
            setTitle('');
            setDescription('');
            setQuery('');
            setFile(null);
            setThumbUrl('');
            setDirty(false);
        }
        refetch();
    };

    useEffect(() => {
        if (title || description || query || file) {
            setDirty(true);
        } else {
            setDirty(false);
        }
    }, [title, description, query, file]);

    const handleTitle = e => {
        setTitle(e.target.value);
    };

    const handleDescription = e => {
        setDescription(e.target.value);
    };

    const handleQuery = e => {
        setQuery(e.target.value);
    };

    const handleClose = () => {
        setTitle('');
        setDescription('');
        setQuery('');
        setFile(null);
        setDirty(false);
        props.handleClose();
    };

    return (
        <div className='Edit__Group__EditItem'>
            {item && <Icon name='caret-down' className='Edit__Group__Item__caret pointer' onClick={handleClose} />}
            <div className='Edit__Group__EditItem__Top flex'>
                <Subtitle>{item ? 'Edit Item' : 'New Item'}</Subtitle>
                <Icon name='trash-can' />
            </div>
            <div className='flex align-flex-end flex-start'>
                <div className='Edit__Group__Thumbnail'>
                    <Subtitle>Thumbnail (Preferred Dimensions: 190w x 220h)</Subtitle>
                    <Dropzone
                        className='Edit__Group__Dropzone'
                        handleFiles={handleFiles}
                        handleUpload={() => {}}
                        file={file}
                        resetFile={() => setFile(null)}
                        loading={loading}
                        thumbUrl={thumbUrl && `${apiUrl}/image${thumbUrl}`}
                    />
                </div>
                <div>
                    <TextField
                        label='Title'
                        value={title}
                        onChange={handleTitle}
                    />
                    <TextArea
                        label='Description (25 characters max)'
                        value={description}
                        onChange={handleDescription}
                    />
                    <TextArea
                        label='Query'
                        value={query}
                        onChange={handleQuery}
                        padded={false}
                    />
                </div>
            </div>
            <DialogFooter
                confirmText={item ? 'Update Item' : 'Add New Item'}
                loading={loading}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default EditGroupItem;

EditGroupItem.propTypes = {
    item: PropTypes.object,
    createGroup: PropTypes.func,
    handleClose: PropTypes.func
};
