const filterConfig = [
    {
        title: 'characters',
        value: 'characters',
        type: 'search',
        slider: true
    },
    {
        title: 'Key Objects',
        value: 'props',
        type: 'search',
        slider: true
    },
    {
        title: 'locations',
        value: 'locations',
        type: 'search',
        slider: false
    },
    {
        title: 'text',
        value: 'text',
        type: 'search',
        slider: false
    },
    {
        title: 'issue',
        value: 'series',
        type: 'search',
        slider: false
    },
    {
        title: 'published date',
        value: 'publication_range',
        type: 'range',
        slider: true,
        classnames: 'Filter--range'
    },
    {
        title: 'writer',
        value: 'creators',
        type: 'search',
        slider: false
    }
    // {
    //     title: <Icon name='settings' />,
    //     type: 'options',
    //     uppercase: false,
    //     classnames: 'HomeSearch__Filter__Options'
    // }
];

export default filterConfig;
