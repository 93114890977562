import { useEffect, useRef } from 'react';

/**
 * Run useEffect after mount rather than on mount
 *
 * @param {callback} callback The effect callback that will run for the first time after initial mount
 * @param {dependencyList} dependencies The dependencies for the effect
 */

const useAfterMountEffect = (callback, dependencies) => {
    const isMounted = useRef(true);

    useEffect(() => {
        if (isMounted.current) {
            isMounted.current = false;
        } else {
            return callback();
        }
    }, dependencies);
};
export default useAfterMountEffect;
