import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Heading } from './Typography';
import dragOn from '../../images/drag_icon_on.svg';
import dragOff from '../../images/drag_icon_off.svg';
import Button from './Button';

const Dropzone = props => {
    const [file, setFile] = useState(null);
    useEffect(() => {
        if (props.file) {
            setFile(URL.createObjectURL(props.file));
        } else {
            setFile(null);
        }
    }, [props.file]);
    const onDrop = useCallback(acceptedFiles => {
        props.handleFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleCancel = e => {
        e.stopPropagation();
        props.handleCancel();
    };

    const resetFile = e => {
        e.stopPropagation();
        props.resetFile();
    };

    const handleConfirm = e => {
        e.stopPropagation();
        props.handleUpload();
    };

    return (
        <div className={`Dropzone ${props.className || ''}`} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <img src={dragOn} />
            ) : (
                <div className='flex column align-center'>
                    {file || props.thumbUrl ? (
                        <>
                            <img src={file || props.thumbUrl} className='Dropzone__Image' />
                            {props.confirmText ? (
                                <>
                                    <Button type='primary' className='Dropzone__Button' onClick={handleConfirm} loading={props.loading}>{props.confirmText}</Button>
                                    <Button onClick={resetFile}>CANCEL</Button>
                                </>
                            ) : ''}
                        </>
                    ) : (
                        <>
                            <Heading number={5} className='Dropzone__Title'>Drag or drop image here</Heading>
                            <img src={dragOff} className='Dropzone__Image--off' />
                            <Button type='primary' className='Dropzone__Button' loading={props.loading}>UPLOAD AN IMAGE FILE</Button>
                            <Button onClick={handleCancel}>CANCEL</Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dropzone;

Dropzone.propTypes = {
    file: PropTypes.object,
    handleFiles: PropTypes.func,
    handleCancel: PropTypes.func,
    resetFile: PropTypes.func,
    handleUpload: PropTypes.func,
    loading: PropTypes.bool,
    className: PropTypes.string,
    thumbUrl: PropTypes.string,
    confirmText: PropTypes.string
};
