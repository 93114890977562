import React, { createContext, useContext, useState } from 'react';
import fetch from '../../utils/fetch';
import UserAction from '../admin/UserAction';
import UsersTable from '../admin/UsersTable';
import { Caption, Heading } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';

export const AdminContext = createContext();

const Admin = props => {
    const { allUsers, refetch } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [emailText, setEmailText] = useState('');
    const [role, setRole] = useState('');
    const [user, setUser] = useState(null);
    const [active, setActive] = useState(true);
    const [notify, setNotify] = useState(false);

    const roles = [
        {
            text: 'Admin - Ability to add/edit/remove users',
            value: 'admin'
        },
        {
            text: 'Elevated user - Ability to download images',
            value: 'elevated'
        },
        {
            text: 'Regular user - Read, search, and create collections only',
            value: 'regular'
        }
    ];

    const handleClose = () => {
        setOpen(false);
        setUser(null);
        setRole('');
        setActive(true);
        setEmailText('');
    };

    const createUser = async () => {
        await fetch('/user/create/', {
            method: 'POST',
            body: {
                email: emailText,
                role,
                active
            }
        });
        refetch();
        handleClose();
    };

    const updateUser = async () => {
        if (user) {
            await fetch(`/user/update/${user.id}`, {
                method: 'PUT',
                body: {
                    role,
                    active,
                    blocked: !active
                }
            });
            refetch();
        }
        handleClose();
    };

    const openUserAction = (type, user) => {
        if (user) {
            setUser(user);
            setRole(user.role.toLowerCase());
            setActive(user.active);
        }
        setOpen(true);
        setActionType(type);
    };

    return (
        <AdminContext.Provider value={{
            role,
            roles,
            actionType,
            active,
            notify,
            emailText,
            allUsers,
            user,
            searchTerm,
            setEmailText,
            setNotify,
            setActive,
            setRole,
            setActionType,
            openUserAction,
            handleClose,
            createUser,
            updateUser
        }}
        >
            <>
                <div className='Admin__Header flex'>
                    <div className='Admin__Header-right flex column'>
                        <Caption className='Admin__Header__Caption'>ADMIN</Caption>
                        <Heading className='Admin__Header__Heading' heavy number={5}>User Management - Users</Heading>
                        <TextField
                            className='Admin__Header__Textfield'
                            placeholder='SEARCH BY NAME, EMAIL, OR ROLE'
                            icon='search'
                            value={searchTerm}
                            styleType='dark'
                            onChange={e => setSearchTerm(e.target.value)}
                            padded={false}
                        />
                    </div>
                    <Button
                        className='Admin__Header__button'
                        type='primary'
                        size='regular'
                        icon='plus'
                        onClick={() => openUserAction('setup', null)}
                    >
                        Add User
                    </Button>
                </div>

                <UsersTable className='Admin__Table' />
                <UserAction open={open} />
            </>
        </AdminContext.Provider>
    );
};

export default Admin;
