import React, { useState, useContext } from 'react';
import focus from '../../images/ico_plus.svg';
import remove from '../../images/ico_minus.svg';
import { Caption } from '../modules/Typography';
import Button from '../modules/Button';
import ImageRegion from '../modules/ImageRegion';
import { UserContext } from '../../contexts/UserContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';

const ImageSearch = props => {
    const { imagePaths, setImagePaths } = useContext(DataContext);
    const { apiUrl } = useContext(UserContext);
    const [target, setTarget] = useState(false);
    const [height, setHeight] = useState(100);
    const [width, setWidth] = useState(100);
    const [totalWidth, setTotalWidth] = useState(0);
    const [totalHeight, setTotalHeight] = useState(0);
    const [x, setX] = useState(10);
    const [y, setY] = useState(10);

    const image = imagePaths[0];

    const handleCrop = () => {
        if (target) {
            setImagePaths([{ ...image, region: null }]);
        }
        setTarget(!target);
    };

    const handleDrag = (e, d) => {
        setX(d.x);
        setY(d.y);
    };

    const handleResize = (e, direction, ref, delta, position) => {
        setWidth(parseInt(ref.style.width));
        setHeight(parseInt(ref.style.height));
        setX(position.x);
        setY(position.y);
    };

    const handleSearch = async () => {
        setImagePaths([
            {
                ...image,
                region: {
                    top: y / totalHeight,
                    left: x / totalWidth,
                    width: width / totalWidth,
                    height: height / totalHeight
                }
            }
        ]);
    };
    const paths = image.image_path.split('/');
    const filename = paths[paths.length - 1].split('_')[2];

    return (
        <div className='ImageGrid__ImageSearch'>
            <div className='flex'>
                <Caption className='ImageGrid__ImageSearch__text'>{filename}</Caption>

                {/* <MenuSurface
                    className='MenuSurface--striped'
                    options={[
                        { text: 'Save this Search' },
                        { text: 'Share this Search' },
                        { text: 'Clear Search' }
                    ]}
                >
                    <Icon name='ellipse-a' className='primary-text button' onClick={() => setMenu(!menu)} />
                </MenuSurface> */}
            </div>
            <ImageRegion
                src={`${apiUrl}/image${image.image_path}`}
                target={target}
                x={x}
                y={y}
                height={height}
                width={width}
                handleDrag={handleDrag}
                handleResize={handleResize}
                setTotalHeight={setTotalHeight}
                setTotalWidth={setTotalWidth}
            />
            <div className='flex ImageGrid__ImageSearch__footer'>
                <div className='flex flex-start'>
                    <img className='ImageGrid__ImageSearch__icon button' src={target ? remove : focus} onClick={handleCrop} />
                    <Caption className='ImageGrid__ImageSearch__text'>{target ? 'REMOVE CROP' : 'TARGET SEARCH'}</Caption>
                </div>
                {target && <Button type='primary' size='small' onClick={handleSearch}>Submit Visual Search</Button>}
            </div>
        </div>
    );
};

export default ImageSearch;
