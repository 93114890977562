import React from 'react';
import PropTypes from 'prop-types';

const Filter = ({ fill }) => (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>icons8-slider-2</title>
        <defs>
            <filter colorInterpolationFilters='auto' id='filter-1'>
                <feColorMatrix in='SourceGraphic' type='matrix' values='0 0 0 0 0.710285 0 0 0 0 0.710285 0 0 0 0 0.710285 0 0 0 1.000000 0' />
            </filter>
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='icons8-slider-2' transform='translate(-236.000000, -558.000000)' filter='url(#filter-1)'>
                <g style={{ mixBlendMode: 'luminosity' }} transform='translate(236.000000, 558.000000)'>
                    <path d='M8,0 C6.7068414,0 5.6048587,0.84267 5.1875,2 L0,2 L0,4 L5.1875,4 C5.6048587,5.15733 6.7068414,6 8,6 C9.64501,6 11,4.6450096 11,3 C11,1.3549904 9.64501,0 8,0 Z M8,2 C8.564129,2 9,2.4358706 9,3 C9,3.5641294 8.564129,4 8,4 C7.4358706,4 7,3.5641294 7,3 C7,2.4358706 7.4358706,2 8,2 Z M13,2 L13,4 L20,4 L20,2 L13,2 Z M13,7 C11.706841,7 10.604859,7.8426699 10.1875,9 L0,9 L0,11 L10.1875,11 C10.604859,12.15733 11.706841,13 13,13 C14.64501,13 16,11.64501 16,10 C16,8.35499 14.64501,7 13,7 Z M13,9 C13.564129,9 14,9.435871 14,10 C14,10.564129 13.564129,11 13,11 C12.435871,11 12,10.564129 12,10 C12,9.435871 12.435871,9 13,9 Z M18,9 L18,11 L20,11 L20,9 L18,9 Z M5,14 C3.7068414,14 2.6048587,14.84267 2.1875,16 L0,16 L0,18 L2.1875,18 C2.6048587,19.15733 3.7068414,20 5,20 C6.6450096,20 8,18.64501 8,17 C8,15.35499 6.6450096,14 5,14 Z M5,16 C5.5641294,16 6,16.435871 6,17 C6,17.564129 5.5641294,18 5,18 C4.4358706,18 4,17.564129 4,17 C4,16.435871 4.4358706,16 5,16 Z M10,16 L10,18 L20,18 L20,16 L10,16 Z' id='Shape' fill='#FF2D2D' fillRule='nonzero' />
                </g>
            </g>
        </g>
    </svg>
);

Filter.propTypes = {
    fill: PropTypes.string
};

export default Filter;
