import React from 'react';
import Icon from './Icon';
import PropTypes from 'prop-types';
import { Caption } from './Typography';

const FormWrapper = ({ onSubmit, children }) => onSubmit ? (
    <form
        className='TextField__form'
        onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e.target.querySelector('input').value);
        }}
        children={children}
    />
) : (
    children
);
FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const TextField = props => {
    return (
        <div className={`TextField ${props.className} ${props.padded ? 'TextField--padded' : ''} ${props.small ? 'TextField--small' : ''}`}>

            {props.label && (
                <label
                    className={`TextField__label ${props.className ? `${props.className}__label` : ''}`}
                    htmlFor={props.id || props.label}
                >
                    <Caption faded={props.disabled}>{props.label}</Caption>
                </label>
            )}

            <div className={[
                'TextField__style-wrapper',
                `TextField__style-wrapper--${props.styleType}`,
                props.disabled ? 'TextField__style-wrapper--disabled' : null,
                props.error ? 'TextField__style-wrapper--error' : null
            ].filter(i => i).join(' ')}
            >
                {props.icon && <Icon className='TextField__icon' name={props.icon} />}

                <FormWrapper onSubmit={props.onSubmit}>
                    <input
                        type={props.type}
                        id={props.id}
                        defaultValue={props.defaultValue}
                        className='TextField__input'
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        onKeyPress={props.onKeyPress}
                        value={props.value}
                        disabled={props.disabled}
                        autoFocus={props.autofocus}
                        onClick={props.onClick}
                    />
                </FormWrapper>

            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextField__error'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default TextField;

TextField.propTypes = {
    // tag id to attach to the form field
    id: PropTypes.string,

    // icon name to display inside the form field
    icon: PropTypes.string,

    // className to pass to the tags
    className: PropTypes.string,

    // placeholder text to place in the form
    placeholder: PropTypes.string,

    // whether or not the field is invalid
    error: PropTypes.bool,

    // error message to display
    errorMessage: PropTypes.string,

    // function to submit single textfield form
    onSubmit: PropTypes.func,

    // function to handl the form field value changeg
    onChange: PropTypes.func.isRequired,

    // function to handle a keypress on the input field
    onKeyPress: PropTypes.func,

    // function to handle a click on the input field
    onClick: PropTypes.func,

    // value of the text field
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // type of the input field
    type: PropTypes.string,

    // label to add to the textfield
    label: PropTypes.string,

    // whether or not the textfield is disabled
    disabled: PropTypes.bool,

    // whether or not the field should autofocus
    autofocus: PropTypes.bool,

    // style of the textfield
    styleType: PropTypes.oneOf(['default', 'dark', 'white', 'underline']),

    // whether or not the textfield has padding below
    padded: PropTypes.bool,
    small: PropTypes.bool,

    // default value to pass to input
    defaultValue: PropTypes.string
};

TextField.defaultProps = {
    className: '',
    error: false,
    type: 'text',
    disabled: false,
    autofocus: false,
    styleType: 'default',
    padded: true
};
