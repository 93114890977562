import React from 'react';
import WidthContextProvider from '../contexts/WidthContextProvider';
import UserContextProvider from '../contexts/UserContextProvider';
import DataContextProvider from '../contexts/DataContextProvider';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import '../styles/main.scss';

import Home from './pages/Home';
import Layout from './layout/Layout';
import Search from './pages/Search';
import Collections from './pages/Collections';
import CollectionsGallery from './pages/CollectionsGallery';
import EditHomePage from './pages/EditHomePage';
import Admin from './pages/Admin';

const routes = [
    { path: '/', component: Home },
    { path: '/search', component: Search },
    { path: '/collections/:id', component: CollectionsGallery },
    { path: '/collections', component: Collections },
    { path: '/edit', component: EditHomePage },
    { path: '/admin', component: Admin }
];

function App () {
    return (
        <Router>
            <UserContextProvider>
                <WidthContextProvider>
                    <DataContextProvider>
                        <Switch>
                            {routes.map((route, key) => {
                                const { path, component, ...props } = route;

                                return (
                                    <Layout key={key} path={path} {...props} exact>
                                        <Route path={path} component={component} exact />
                                    </Layout>
                                );
                            })}
                            <Redirect to='/' />
                        </Switch>
                    </DataContextProvider>
                </WidthContextProvider>
            </UserContextProvider>
        </Router>
    );
}

export default App;
