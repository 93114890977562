import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import DialogWrapper from './DialogWrapper';
import IconButton from './IconButton';
import { Subtitle, Caption } from './Typography';
import { UserContext } from '../../contexts/UserContextProvider';
import Dropzone from './Dropzone';

const EditCoverModal = props => {
    const { user, apiUrl } = useContext(UserContext);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFiles = files => {
        setFile(files[0]);
    };

    const handleUpload = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('userId', user.id);
        formData.append('file', file);
        await fetch(`/usercollections/uploadthumb/${props.collection.id}`, {
            method: 'POST',
            body: formData
        });
        setLoading(false);
        props.onConfirm();
        props.handleCancel();
    };
    if (!props.collection) return <div />;
    return (
        <DialogWrapper
            open={props.open}
            className='ActionModal EditCoverModal'
            handleCancel={props.handleCancel}
        >
            <div className='flex'>
                <Subtitle>Edit Cover Image</Subtitle>
                <IconButton className='ActionModal__Close' name='close' onClick={props.handleCancel} />
            </div>
            <div className='flex align-flex-start'>
                <div>
                    <Caption>Current Cover Image</Caption>
                    <div className='EditCoverModal__CoverImage' style={{ backgroundImage: `url("${apiUrl}/image${props.collection.thumb_url}")` }} />
                </div>
                <div>
                    <Caption>Choose another image</Caption>
                    <Dropzone
                        className='EditCoverModal__Dropzone'
                        handleFiles={handleFiles}
                        handleCancel={props.handleCancel}
                        file={file}
                        resetFile={() => setFile(null)}
                        handleUpload={handleUpload}
                        loading={loading}
                        confirmText='SAVE IMAGE'
                    />
                </div>
            </div>
        </DialogWrapper>
    );
};

export default EditCoverModal;

EditCoverModal.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    collection: PropTypes.object,
    onConfirm: PropTypes.func
};
