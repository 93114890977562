import React, { useState, useEffect, useContext } from 'react';
import fetch from '../../utils/fetch';
import SearchFilters from '../home/SearchFilters';
import Button from '../modules/Button';
import TabBar from '../modules/TabBar';
import Select from '../modules/Select';
import ActionModal from '../modules/ActionModal';
import CollectionsGrid from '../collections/CollectionsGrid';
import { UserContext } from '../../contexts/UserContextProvider';
// import CollectionsTree from '../collections/CollectionsTree';
// import gridView from '../../images/ico_gallery_off.svg';
// import treeView from '../../images/ico_tree_off.svg';
// import { Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import EditCoverModal from '../modules/EditCoverModal';
import Loading from '../modules/Loading';

export const CollectionsContext = React.createContext();

const tabs = ['All', 'My Collections', 'Shared with me'];

const Collections = props => {
    const { user } = useContext(UserContext);
    const [selectedTab, setTab] = useState('All');
    const [sort, setSort] = useState('name');
    // const [layout, setLayout] = useState('grid');
    const [collections, setCollections] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editOpen, setEditModalOpen] = useState(false);
    const [modalType, setModal] = useState('');
    const [menuCollection, setMenuCollection] = useState(null);
    // const [parentId, setParentId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchCollections = async () => {
        setLoading(true);
        const response = await fetch(`/usercollections/all/${sort}`);
        setCollections(response);
        setLoading(false);
    };

    useEffect(() => {
        fetchCollections();
    }, [sort]);

    const handleModal = (modalType, collection) => {
        if (modalType === 'edit') {
            setEditModalOpen(true);
        } else {
            setModal(modalType);
            setModalOpen(true);
        }
        setMenuCollection(collection);
        // setParentId(parentId || null);
    };

    const totals = [collections.length, collections.filter(col => col.owner === user.id).length, collections.filter(col => col.owner !== user.id).length];

    const visibleCollections = selectedTab === 'All'
        ? collections : collections.filter(col => (
            selectedTab === 'My Collections'
                ? col.owner === user.id
                : col.owner !== user.id));

    const filteredCollections = searchTerm
        ? collections.filter(collection => (
            collection.name.toLowerCase().includes(searchTerm.toLowerCase())))
        : visibleCollections;

    if (loading) return <Loading />;

    return (
        <CollectionsContext.Provider value={{ handleModal, fetchCollections }}>
            <div className='Collections'>
                <SearchFilters />
                <div className='Collections__Content '>
                    <div className='Collections__Header'>
                        <div className='Collections__Header__Selectors relative'>
                            <TabBar tabs={tabs} selectedTab={selectedTab} updateTab={tab => setTab(tab)} totals={totals} />
                            <Button
                                className='Collections__Header__button'
                                type='primary'
                                size='small'
                                icon='plus-circle'
                                onClick={() => handleModal('new', null)}
                            >
                                NEW COLLECTION
                            </Button>
                        </div>
                        <div className='flex'>
                            <TextField
                                className='Collections__Header__TextField'
                                small
                                icon='search'
                                placeholder='SEARCH COLLECTIONS'
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <div className='flex align-center'>
                                <Select
                                    placeholder='Sort'
                                    className='Collections__Header__Select'
                                    styleType='dark'
                                    bodyText
                                    selectedValue={sort}
                                    options={[
                                        { text: 'A - Z', value: 'name' },
                                        { text: 'Z - A', value: '-name' },
                                        { text: 'Newest First', value: '-created_at' },
                                        { text: 'Oldest First', value: 'created_at' }
                                    ]}
                                    onChange={({ value }) => setSort(value)}
                                />
                                {/* <div className='flex column'>
                                    <div className='Collections__Header__right flex'>
                                        <div className='Collections__View__before' />
                                        <Caption number={2} className='Collections__View'>View</Caption>
                                        <div className='Collections__View__after' />
                                    </div>
                                    <div className='Collections__Header__right flex'>
                                        <img src={treeView} className='pointer' onClick={() => setLayout('tree')} />
                                        <img src={gridView} className='pointer' onClick={() => setLayout('grid')} />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* {layout === 'grid' ? ( */}
                    <CollectionsGrid collections={filteredCollections} /> {/* TODO - this is where Ill pick the collections based on the tab */}

                    {/* ) : (
                        <CollectionsTree collections={filteredCollections} />
                    )} */}
                </div>
                <EditCoverModal
                    open={editOpen}
                    handleCancel={() => setEditModalOpen(false)}
                    collection={menuCollection}
                    onConfirm={fetchCollections}
                />
                <ActionModal
                    open={modalOpen}
                    handleCancel={() => setModalOpen(false)}
                    modalType={modalType}
                    collection={menuCollection}
                    onConfirm={fetchCollections}
                />
            </div>
        </CollectionsContext.Provider>

    );
};

export default Collections;
