import React from 'react';
import PropTypes from 'prop-types';

const ExpandArrow = ({ fill }) => (
    <svg width='18px' height='23px' viewBox='0 0 18 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
        <title>Group 6</title>
        <defs>
            <rect id='path-1' x='0' y='0' width='15' height='23' />
            <linearGradient x1='12.5078129%' y1='63.7098129%' x2='100%' y2='63.7098129%' id='linearGradient-3'>
                <stop stopColor='#B31900' offset='0%' />
                <stop stopColor='#9E0000' offset='100%' />
            </linearGradient>
            <rect id='path-4' x='-9' y='0' width='23' height='23' rx='9' />
            <filter x='-163.0%' y='-163.0%' width='417.4%' height='413.0%' filterUnits='objectBoundingBox' id='filter-5'>
                <feOffset dx='4' dy='3' in='SourceAlpha' result='shadowOffsetOuter1' />
                <feGaussianBlur stdDeviation='9.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
                <feComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1' />
                <feColorMatrix values='0 0 0 0 0.052932895   0 0 0 0 0.0605070153   0 0 0 0 0.0587009581  0 0 0 1 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1' />
                <feOffset dx='-5' dy='-5' in='SourceAlpha' result='shadowOffsetOuter2' />
                <feGaussianBlur stdDeviation='10' in='shadowOffsetOuter2' result='shadowBlurOuter2' />
                <feComposite in='shadowBlurOuter2' in2='SourceAlpha' operator='out' result='shadowBlurOuter2' />
                <feColorMatrix values='0 0 0 0 0.249879473   0 0 0 0 0.307424532   0 0 0 0 0.290682908  0 0 0 1 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2' />
                <feMerge>
                    <feMergeNode in='shadowMatrixOuter1' />
                    <feMergeNode in='shadowMatrixOuter2' />
                </feMerge>
            </filter>
            <filter x='-126.1%' y='-126.1%' width='343.5%' height='339.1%' filterUnits='objectBoundingBox' id='filter-6'>
                <feGaussianBlur stdDeviation='3.5' in='SourceAlpha' result='shadowBlurInner1' />
                <feOffset dx='1' dy='1' in='shadowBlurInner1' result='shadowOffsetInner1' />
                <feComposite in='shadowOffsetInner1' in2='SourceAlpha' operator='arithmetic' k2='-1' k3='1' result='shadowInnerInner1' />
                <feColorMatrix values='0 0 0 0 0.105882353   0 0 0 0 0.596078431   0 0 0 0 0.509803922  0 0 0 1 0' type='matrix' in='shadowInnerInner1' />
            </filter>
            <filter colorInterpolationFilters='auto' id='filter-7'>
                <feColorMatrix in='SourceGraphic' type='matrix' values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0' />
            </filter>
            <path d='M5.27744553,3.00584059 C5.49922476,2.78406137 5.85879993,2.78406137 6.08057916,3.00584059 C6.30235839,3.22761982 6.30235839,3.58719499 6.08057916,3.80897422 L6.08057916,3.80897422 L3.6427037,6.24650617 L11.3580247,6.24691358 C11.6403036,6.24691358 11.8744805,6.4528626 11.9184931,6.72269828 L11.9259259,6.81481481 C11.9259259,7.12845801 11.6716679,7.38271605 11.3580247,7.38271605 L11.3580247,7.38271605 L3.6427037,7.38250617 L6.08057916,9.82065541 C6.27771625,10.0177925 6.29962037,10.3238054 6.14629152,10.5451371 L6.08057916,10.623789 C5.85879993,10.8455683 5.49922476,10.8455683 5.27744553,10.623789 L5.27744553,10.623789 L1.87003812,7.21638163 L1.87003812,7.21638163 L1.8132757,7.15020991 C1.812112,7.14862237 1.81095636,7.14702857 1.80980882,7.14542855 C1.80191321,7.13448857 1.79468786,7.12362609 1.78787597,7.11258086 C1.77788206,7.09631602 1.76843441,7.07910744 1.75988012,7.06138674 C1.75511187,7.05154875 1.75065542,7.04156512 1.74650136,7.03148618 C1.73871271,7.01267363 1.73178438,6.99293328 1.72594775,6.97272974 C1.72259525,6.96091107 1.71971409,6.94956846 1.71718789,6.93816307 C1.70838106,6.89880258 1.7037037,6.85735493 1.7037037,6.81481481 L1.70758612,6.8813055 C1.70602636,6.86799855 1.70493658,6.85464699 1.70431677,6.84127771 L1.7037037,6.81481481 C1.7037037,6.80595099 1.70390677,6.79713459 1.70430852,6.78837001 C1.70490063,6.77524373 1.70605303,6.76115649 1.70772859,6.7471198 C1.71007294,6.72734734 1.71328917,6.70866264 1.71739338,6.69031318 C1.71975924,6.67993172 1.72247262,6.66923448 1.72550169,6.65860826 C1.7313389,6.63800469 1.73835581,6.61787788 1.74643544,6.59830152 C1.75073619,6.58782399 1.75565144,6.57685347 1.76093214,6.56601613 C1.76967902,6.54820303 1.77898932,6.53141391 1.78909682,6.51517245 C1.81147955,6.4790692 1.83851911,6.44476701 1.87003812,6.413248 L1.80782486,6.48698494 C1.82646592,6.46066143 1.84729172,6.43599441 1.87003812,6.413248 Z' id='path-8' />
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search-Result-Copy-2' transform='translate(-160.000000, -203.000000)'>
                <g id='Group-5' transform='translate(160.740741, 203.000000)'>
                    <g id='Rectangle-Copy-21' transform='translate(1.259259, 0.000000)'>
                        <mask id='mask-2' fill='white'>
                            <use xlinkHref='#path-1' />
                        </mask>
                        <use id='Mask' fill='#000000' xlinkHref='#path-1' />
                        <g id='Rectangle-Copy-20' fillRule='nonzero' mask='url(#mask-2)'>
                            <use fill='black' fillOpacity='1' filter='url(#filter-5)' xlinkHref='#path-4' />
                            <use fill='url(#linearGradient-3)' xlinkHref='#path-4' />
                            <use fill='black' fillOpacity='1' filter='url(#filter-6)' xlinkHref='#path-4' />
                            <rect stroke='#FF0000' strokeWidth='2' strokeLinejoin='square' x='-8' y='1' width='21' height='21' rx='9' />
                        </g>
                    </g>
                    <g id='Icons/Arrow/Select_Arrow_Down-Copy' transform='translate(0.000000, 4.259259)' filter='url(#filter-7)'>
                        <g transform='translate(6.814815, 6.814815) scale(-1, 1) translate(-6.814815, -6.814815) '>
                            <mask id='mask-9' fill='white'>
                                <use xlinkHref='#path-8' />
                            </mask>
                            <use id='Combined-Shape' fill='#1F1F20' xlinkHref='#path-8' />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ExpandArrow.propTypes = {
    fill: PropTypes.string
};

export default ExpandArrow;
