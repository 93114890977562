import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import fetch from '../../utils/fetch';
import SearchFilters from '../home/SearchFilters';
import ComicDrawer from '../search/ComicDrawer';
import { Caption, Subtitle } from '../modules/Typography';
import Button from '../modules/Button';
import UserCircleList from '../modules/UserCircleList';
import Loading from '../modules/Loading';
import ActionModal from '../modules/ActionModal';
import EditCoverModal from '../modules/EditCoverModal';
import AddMember from '../collections/AddMember';
import ImageGrid from '../modules/ImageGrid';
import holder from '../../images/cover.png';
import plus from '../../images/ico-plus_circle.svg';
import CollectionPopup from '../collections/CollectionPopup';
import { UserContext } from '../../contexts/UserContextProvider';

export const CollectionsGalleryContext = React.createContext();

const CollectionsGallery = props => {
    const location = useLocation();
    const history = useHistory();
    const { apiUrl } = useContext(UserContext);

    // The collection
    const collectionId = location.pathname.split('/')[2];
    const [collection, setCollection] = useState(null);
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [comics, setComics] = useState([]);
    const [sortBy, setSort] = useState('');
    const [deleteImageId, setDeleteImageId] = useState(null);

    // Action Modal
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModal] = useState('');

    const handleModal = (modalType) => {
        if (modalType === 'edit') {
            setEditModalOpen(true);
        } else {
            setModal(modalType);
            setModalOpen(true);
        }
    };

    /* Get the collection */
    useEffect(() => {
        if (!collection) getCollection();

        setLoading(!collection);
    }, [collection]);

    const getCollection = async (id) => {
        const response = await fetch(`/usercollections/${collectionId}`);
        setCollection(response);
        const comicList = response.comic_images.map(comic => (
            comic.metadata)).reduce(
            (prev, curr) => {
                const comic = curr.comic_info;
                if (!prev[comic.id]) {
                    prev[comic.id] = { ...comic, total: 0 };
                }
                prev[comic.id].total++;
                return prev;
            }, {});
        setComics(comicList);
        setAddOpen(false);
    };

    const handleDelete = async (id) => {
        setDeleteImageId(id);
        await fetch(`/deleteImageFromCollection/${collectionId}`, {
            method: 'POST',
            body: {
                imageIds: [id]
            }
        });
        getCollection();
    };

    if (!collection) return <Loading />;
    const imagePath = collection.thumb_url ? `${apiUrl}/image${collection.thumb_url}` : holder;

    // Reorder collection members for owner first
    const owner = collection.users.find(user => user.id === collection.owner);
    const orderedMembers = [owner, ...collection.users.filter(user => user.id !== collection.owner)];

    return (
        <CollectionsGalleryContext.Provider value={{ comics, handleModal, sortBy, setSort, getCollection, handleDelete }}>
            <>
                <SearchFilters />
                {!loading ? (
                    <div className='CollectionsGallery__Page flex align-flex-start'>
                        <ComicDrawer collection />
                        <div style={{ width: '100%' }}>
                            <div className='CollectionsGallery__Header flex'>
                                <img className='CollectionsGallery__Header__Image' src={imagePath} />
                                <div className='CollectionsGallery__Header__center flex column'>
                                    <div className='CollectionsGallery__Header__Title flex column'>
                                        <div className='flex'>
                                            <CollectionPopup collection={collection} gallery />
                                            <Subtitle number={1}>{`${collection.name ? collection.name.toUpperCase() : 'LOADING'}`}</Subtitle>
                                        </div>
                                        <Caption className='CollectionsGallery__Header__Caption'>{`${collection.comic_images.length} Images`}</Caption>
                                    </div>
                                    <div className='CollectionsGallery__Header__Members flex'>
                                        <UserCircleList users={orderedMembers} />
                                        <Button className='CollectionsGallery__Header__Button CollectionsGallery__Header__Members__Add' onClick={() => setAddOpen(true)}>
                                            <img src={plus} />
                                        </Button>
                                        {addOpen ? (
                                            <AddMember
                                                setAddOpen={setAddOpen}
                                                collectionMembers={orderedMembers}
                                                collectionId={collectionId}
                                                collectionOwner={collection.owner}
                                            />
                                        ) : (
                                            <Subtitle number={2} style={{ lineHeight: '1.125rem' }}>Add</Subtitle>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='CollectionsGallery__Body'>
                                <ImageGrid
                                    images={collection.comic_images
                                        .map(image => {
                                            image.metadata.userNote = image.user_note;
                                            return (
                                                image.metadata
                                            );
                                        })
                                        .filter(image => image.id !== deleteImageId)}
                                    upload={false}
                                    imageHeader={false}
                                    collectionId={collectionId}
                                    collectionName={collection.name}
                                />
                            </div>
                        </div>
                        <EditCoverModal
                            open={editOpen}
                            handleCancel={() => setEditModalOpen(false)}
                            collection={collection}
                            onConfirm={() => history.goBack()}
                        />
                        <ActionModal
                            open={modalOpen}
                            handleCancel={() => setModalOpen(false)}
                            modalType={modalType}
                            collection={collection}
                            onConfirm={() => history.goBack()}
                        />
                    </div>
                ) : (
                    <Loading />
                )}

            </>
        </CollectionsGalleryContext.Provider>
    );
};

export default CollectionsGallery;
