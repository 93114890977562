import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Caption } from './Typography';
import UserCircle from './UserCircle';
import { AddMemberContext } from '../collections/AddMember';

const CustomSurfaceOption = props => {
    const { handleAddMember } = useContext(AddMemberContext);
    const [firstName, secondName] = props.text.split(' ');
    const { id, email } = props.value;

    const [loading, setLoading] = useState(false);

    const handleInvite = () => {
        handleAddMember(id);
        setLoading(true);
    };

    return (
        <li className='CustomSurfaceOption' onClick={e => e.stopPropagation()}>
            <div className='flex'>
                <UserCircle className='CollectionMember__UserCircle' firstName={firstName} lastName={secondName} />
                <div className='CustomSurfaceOption__Name flex column'>
                    <Caption className='CustomSurfaceOption__Name all-capitalize' ellipsis>{props.text}</Caption>
                    <Caption className='CustomSurfaceOption__Email all-capitalize' number={2} ellipsis>{email}</Caption>
                </div>
                <div className='CustomSurfaceOption__Invite flex space-around pointer' onClick={handleInvite}>
                    {!loading ? (
                        <Caption className='CustomSurfaceOption--red'>
                            INVITE
                        </Caption>
                    ) : (
                        <Caption className='CustomSurfaceOption--red'>
                            SENT
                        </Caption>
                    )}
                </div>
            </div>
        </li>
    );
};

export default CustomSurfaceOption;

CustomSurfaceOption.propTypes = {
    text: PropTypes.string,
    value: PropTypes.object
};
