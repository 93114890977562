import React, { useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import PropTypes from 'prop-types';
import tr from '../../images/topright_crop.svg';
import tl from '../../images/topleft_crop.svg';
import br from '../../images/btm_right_crop.svg';
import bl from '../../images/btm_left_crop.svg';

const ImageRegion = ({ src, target, width, height, x, y, handleDrag, handleResize, setTotalWidth, setTotalHeight }) => {
    const imageRef = useRef(null);

    const getDimensions = () => {
        if (imageRef.current) {
            const width = imageRef.current.offsetWidth;
            const height = imageRef.current.offsetHeight;
            setTotalWidth(width);
            setTotalHeight(height);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', getDimensions);
        return () => {
            window.removeEventListener('resize', getDimensions);
        };
    }, [imageRef.current]);

    return (
        <div className='ImageRegion'>
            <img
                className='ImageRegion__Image'
                src={src}
                ref={imageRef}
                onLoad={getDimensions}
            />
            {target && (
                <Rnd
                    className='ImageRegion__Window'
                    size={{ width, height }}
                    position={{ x, y }}
                    onDragStop={handleDrag}
                    onResizeStop={handleResize}
                    bounds='parent'
                >
                    <img src={tr} className='ImageRegion__Window__crop ImageRegion__Window__topRight' />
                    <img src={tl} className='ImageRegion__Window__crop ImageRegion__Window__topLeft' />
                    <img src={br} className='ImageRegion__Window__crop ImageRegion__Window__bottomRight' />
                    <img src={bl} className='ImageRegion__Window__crop ImageRegion__Window__bottomLeft' />
                </Rnd>
            )}
        </div>
    );
};

ImageRegion.propTypes = {
    src: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    handleDrag: PropTypes.func,
    handleResize: PropTypes.func,
    setTotalWidth: PropTypes.func,
    setTotalHeight: PropTypes.func,
    target: PropTypes.bool
};

export default ImageRegion;
