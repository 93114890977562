import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import MenuSurface from '../modules/MenuSurface';
import Icon from '../modules/Icon';
import { CollectionsContext } from '../pages/Collections';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';
import fetch from '../../utils/fetch';
import Snackbar from '../modules/Snackbar';

const CollectionPopup = props => {
    const context = props.gallery ? CollectionsGalleryContext : CollectionsContext;
    const history = useHistory();
    const { handleModal } = useContext(context);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleMenu = (modal) => {
        handleModal(modal, props.collection);
    };

    const handleDownload = async () => {
        setSnackbarOpen(true);
        const file = await fetch(`/usercollections/download/${props.collection.id}`, {
            headers: {
                accept: 'application/zip'
            }
        });
        setSnackbarOpen(false);
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = `collection_${props.collection.name}.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    const options = [
        {
            text: 'Edit Cover Image',
            onClick: () => { handleMenu('edit'); }
        },
        {
            text: 'Rename Collection',
            onClick: () => { handleMenu('rename'); }
        },
        {
            text: 'Duplicate',
            onClick: () => { handleMenu('duplicate'); }
        },
        {
            text: 'Download',
            onClick: props.collection.comic_images.length ? () => handleDownload : () => {}
        },
        // {
        //     text: 'Pin',
        //     onClick: () => { handleMenu('rename'); }
        // },
        {
            text: 'Delete',
            onClick: () => { handleMenu('delete'); }
        }
    ];
    if (!props.gallery) {
        options.unshift({
            text: 'View Detail',
            onClick: () => { history.push(`/collections/${props.collection.id}`); }
        });
    }

    return (
        <MenuSurface
            onClick={e => e.stopPropagation()}
            className='MenuSurface--striped'
            options={options}
        >
            <Icon
                name='ellipse-b'
                className='active-text button'
                onClick={e => {
                    e.preventDefault();
                }}
            />
            <Snackbar
                open={snackbarOpen}
                closeDelay={30000}
                text='Your collection is downloading. Please do not navigate away from this page.'
                handleClose={() => setSnackbarOpen(false)}
            />
        </MenuSurface>
    );
};

export default CollectionPopup;

CollectionPopup.propTypes = {
    collection: PropTypes.object,
    gallery: PropTypes.bool
};
