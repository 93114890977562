import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import IconButton from '../modules/IconButton';
import { Caption } from '../modules/Typography';
import CollectionMember from '../modules/CollectionMember';
import { CollectionsGalleryContext } from '../pages/CollectionsGallery';
import { UserContext } from '../../contexts/UserContextProvider';
import AddMemberSelect from '../modules/AddMemberSelect';

export const AddMemberContext = React.createContext();

const AddMember = props => {
    const ref = useRef(null);
    const { getCollection } = useContext(CollectionsGalleryContext);
    const { allUsers } = useContext(UserContext);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [searching, setSearching] = useState(false);

    const handleClick = useCallback((e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            props.setAddOpen(false);
        }
    }, [props.setAddOpen]);

    useEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    useEffect(() => {
        const filteredUsers = allUsers.filter((user) => !props.collectionMembers.find(el => user.id === el.id));
        setAvailableUsers(filteredUsers.map(user => {
            return ({ text: `${user.first_name} ${user.last_name}`, value: user });
        }));
    }, []);

    const handleAddMember = async (userId) => {
        await fetch(`/usercollections/add/${props.collectionId}/${userId}`, {
            method: 'PUT'
        });
        getCollection();
    };

    const handleDeleteMember = async (userId) => {
        await fetch(`/usercollections/delete/${props.collectionId}/${userId}`, {
            method: 'DELETE'
        });
        getCollection();
    };

    return (
        <AddMemberContext.Provider value={{ handleAddMember, handleDeleteMember, setSearching }}>
            <div className={`AddMember ${searching ? 'AddMember--open' : ''}`} ref={ref}>
                <div className='AddMember__Wrapper '>
                    <IconButton className='AddMember__Close' name='close-round' size='small' onClick={() => props.setAddOpen(false)} />
                    <Caption className='AddMember__Title AddMember--black'>COLLECTION ACCESS</Caption>
                    <Caption className='AddMember__Info AddMember--black' block number={2}>
                        Limited to email addresses under <span className='AddMember--red'>disney.com</span>, <span className='AddMember--red'>marvelstudios.com</span>, and <span className='AddMember--red'>mcsustudios.com</span>.
                    </Caption>
                    <AddMemberSelect
                        className='AddMember__Select'
                        options={availableUsers}
                        placeholder='Add people by name or email address'
                        custom
                    />
                    <div className='AddMember__Member__Wrapper'>
                        {props.collectionMembers.map((memb, idx) => (
                            <CollectionMember key={idx} {...memb} creator={memb.id === props.collectionOwner} />
                        ))}
                    </div>
                </div>
            </div>
        </AddMemberContext.Provider>
    );
};

export default AddMember;

AddMember.propTypes = {
    setAddOpen: PropTypes.func,
    collectionMembers: PropTypes.array,
    collectionId: PropTypes.string,
    collectionOwner: PropTypes.number
};
