import React from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from '../modules/DialogWrapper';
import IconButton from '../modules/IconButton';
import { Body, Subtitle } from '../modules/Typography';

const SearchHelp = props => {
    return (
        <DialogWrapper
            open={props.open}
            handleCancel={props.handleCancel}
            className='SearchHelp'
        >
            <div className='SearchHelp__Box'>
                <IconButton className='SearchHelp__Close' name='close' onClick={props.handleCancel} />
                <Body> This application supports several commands that can be used for
                    filtering the resulting panels. You can use several commands at the
                    same time.
                    All text right after the command name will be interpreted as the
                    command text (see below for examples).
                    You can combine text searches with image searches, by selecting one or
                    more images and clicking the "Search by text and image" button.
                </Body>
                <table className='SearchHelp__Table'>
                    <tr className='SearchHelp__Table__HeaderRow'>
                        <td colSpan='2'>
                            <Subtitle>Commands</Subtitle>
                        </td>
                        <td>
                            <Subtitle>Examples</Subtitle>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className='code'>/ocr:&lt;OCR TEXT&gt;</span>
                        </td>
                        <td>
                            <Body>Get panels containing the specified text</Body>
                        </td>
                        <td>
                            <span className='code'>/ocr:hulk smash</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className='code'>/year:&lt;YYYY,YYYY&gt;</span>
                        </td>
                        <td>
                            <Body>Get panels from comics from the specified year or year range.</Body>
                        </td>
                        <td>
                            <span className='code'>/year:2015</span>
                        </td>
                    </tr>
                    <tr>
                        <td />
                        <td>
                            <Body offset='3'>NOTE: Years must be expressed with four digits</Body>
                        </td>
                        <td>
                            <span className='code'>/year:2016,2018</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className='code'>/creator:&lt;NAME&gt;</span>
                        </td>
                        <td>
                            <Body>Get panels made by the specified person.</Body>
                        </td>
                        <td>
                            <span className='code'>/creator:Joe Sabino</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className='code'>/metadata:&lt;FILTER WORDS&gt;</span>
                        </td>
                        <td>
                            <Body>Get panels with the specified words in its title and/or
                                synopsis.
                            </Body>
                        </td>
                        <td>
                            <span className='code'>/metadata:Future Avengers</span>
                        </td>
                    </tr>
                    <tr className='SearchHelp__Table__HeaderRow'>
                        <td colSpan='2'>
                            <Subtitle>Operators</Subtitle>
                        </td>
                        <td>
                            <Subtitle>Examples</Subtitle>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='2'>
                            <Body>With these operators you can combine several text queries.</Body>
                        </td>
                        <td>
                            <span className='code'>city - day + night</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='2'>
                            <Body>You can add or substract them together, you can multiply or divide a
                                query by a scalar in order to increase or lower their relevance in the
                                combined query.
                            </Body>
                        </td>
                        <td>
                            <span className='code'>hulk + 2*orange</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='2'>
                            <Body>In case you want to prioritize any operation, you can enclose one or
                                more terms with parenthesis.
                            </Body>
                        </td>
                        <td>
                            <span className='code'>0.5*(hulk - man) + woman</span>
                        </td>
                    </tr>
                </table>
            </div>
        </DialogWrapper>
    );
};

export default SearchHelp;

SearchHelp.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func
};
