import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Caption, Subtitle } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import fetch from '../../utils/fetch';
import EditGroupItem from './EditGroupItem';
import GroupItem from './GroupItem';
import { EditContext } from '../pages/EditHomePage';
import Icon from '../modules/Icon';

export const GroupContext = React.createContext();

const Group = props => {
    const [name, setName] = useState('');
    const [isEdtitingName, setEditingName] = useState(false);
    const { refetch, setDirty } = useContext(EditContext);
    const [openItem, setOpenItem] = useState(null);

    const { group } = props;

    useEffect(() => {
        setEditingName(!group.id);
        setName(group.name);
    }, [group]);

    const createGroup = async () => {
        await fetch('/groups', {
            method: 'POST',
            body: {
                name
            }
        });
        refetch();
    };

    const updateGroup = async () => {
        await fetch(`/groups/${group.id}`, {
            method: 'PUT',
            body: {
                name
            }
        });
        refetch();
    };

    const deleteGroup = async () => {
        await fetch(`/comicgroup/${group.id}/`, {
            method: 'DELETE'
        });
        refetch();
    };

    const handleSave = () => {
        if (group.id) {
            updateGroup();
        } else {
            createGroup();
        }
        setEditingName(false);
    };

    const handleName = e => {
        setName(e.target.value);
        setDirty(true);
    };

    const handleOpen = id => {
        setOpenItem(id);
    };

    return (
        <GroupContext.Provider value={{ groupId: group.id, handleOpen }}>
            <div className='Edit__Group'>
                <div className=' Edit__TopNav__Save flex flex-end'>
                    <Button type='primary' onClick={handleSave}>Save</Button>
                </div>
                <div className='Edit__Group__Card Edit__Group__Name flex'>
                    {isEdtitingName ? (
                        <>
                            <TextField
                                value={name}
                                onChange={handleName}
                                placeholder='Name'
                            />
                            <Button type='link' onClick={handleSave}>Save</Button>
                        </>
                    ) : (
                        <>
                            <div className='flex column'>
                                <Caption number={2} className='Edit__Group__Title'>COLLECTION TITLE</Caption>
                                <Subtitle fadedTwoThirds>{name}</Subtitle>
                            </div>
                            <div>
                                <Button type='link' onClick={() => setEditingName(true)}>Edit</Button>
                                <Icon name='trash-can' className='pointer' onClick={deleteGroup} />
                            </div>
                        </>
                    )}
                </div>
                {group.comic_models?.map(item => (
                    <GroupItem
                        key={`item-${item.id}`}
                        item={item}
                        open={item.id === openItem}
                    />
                ))}
                <div className='Edit__Group__Card Edit__Group__Item open'>
                    <EditGroupItem
                        groupId={group.id}
                        createGroup={createGroup}
                    />
                </div>
            </div>
        </GroupContext.Provider>
    );
};

export default Group;

Group.propTypes = {
    group: PropTypes.object
};
