import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import TextField from '../modules/TextField';
import Icon from '../modules/Icon';
import { Caption } from '../modules/Typography';
import CollectionsMenu from './CollectionsMenu';
import Button from '../modules/Button';

const SaveMenu = props => {
    const ref = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [collections, setCollections] = useState([]);
    const [create, setCreate] = useState(false);
    const [name, setName] = useState('');

    const handleClick = useCallback((e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            if (!e.target.className.includes('SaveMenu') && !e.target.innerText.includes('Cancel')) {
                props.setSaveOpen(false);
            }
        }
    }, [props.setSaveOpen]);

    useEffect(() => {
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    const fetchCollections = async () => {
        const response = await fetch('/usercollections/all/name');
        setCollections(response);
    };

    const handleCreateMode = () => {
        setCreate(!create);
    };

    useEffect(() => { fetchCollections(); }, []);

    useEffect(() => {
        setName('');
    }, [create]);

    const handleCreate = async () => {
        const collection = await fetch('/usercollections', {
            method: 'POST',
            body: {
                name
            }
        });
        await fetch(`/addImageToCollection/${collection.id}`, {
            method: 'POST',
            body: {
                imageIds: [props.imageId]
            }
        });
        fetchCollections();
        handleCreateMode();
    };

    return (
        <div className='ImageGrid__item__SaveMenu' ref={ref}>
            <div className='flex flex-start pointer ImageGrid__item__SaveMenu__top' onClick={create ? handleCreateMode : props.handleClose}>
                <Icon name='caret-left' className='ImageGrid__item__SaveMenu__icon Icon__Circle' />
                <Caption className='ImageGrid__item__SaveMenu__redText'>BACK</Caption>
            </div>
            {!create ? (
                <>
                    <TextField
                        className='ImageGrid__item__SaveMenu__TextField'
                        placeholder='SEARCH'
                        icon='search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <Caption number={2} className='ImageGrid__item__SaveMenu__grayText'>SAVE TO</Caption>
                    <CollectionsMenu className='SaveMenu__Collection' searchTerm={searchTerm} imageId={props.imageId} fetchCollections={fetchCollections} collections={collections} setSaveOpen={props.setSaveOpen} />
                    <div className='ImageGrid__item__SaveMenu__bottom flex flex-start pointer' onClick={handleCreateMode}>
                        <Icon name='plus' className='ImageGrid__item__SaveMenu__icon Icon__Circle' />
                        <Caption className='ImageGrid__item__SaveMenu__redText'>CREATE NEW COLLECTION</Caption>
                    </div>
                </>
            ) : (
                <div className='flex column align-flex-start'>
                    <Caption number={2} className='ImageGrid__item__SaveMenu__grayText ImageGrid__item__SaveMenu__image__header'>CREATE NEW COLLECTION</Caption>
                    <img src={props.imagePath} className='ImageGrid__item__SaveMenu__image' />
                    <Caption number={2} className=''>Default Cover Image</Caption>
                    <TextField
                        className='ImageGrid__item__SaveMenu__TextField'
                        placeholder='Name of your collection'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <div className='flex flex-start ImageGrid__item__SaveMenu__row pointer' onClick={handleCreateMode}>
                        <Icon name='plus' className='ImageGrid__item__SaveMenu__icon Icon__Circle' />
                        <Caption number={2} className='SaveMenu--caption'>Add to an existing collection</Caption>
                        <Icon name='small-caret-right' className='CollectionsMenu__Item__redText' />
                    </div>
                    <div className='flex flex-start ImageGrid__item__SaveMenu__buttons'>
                        <Button type='secondary' size='small' onClick={handleCreateMode}>Cancel</Button>
                        <Button type='primary' size='small' onClick={handleCreate}>Create</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SaveMenu;

SaveMenu.propTypes = {
    handleClose: PropTypes.func,
    imageId: PropTypes.string,
    imagePath: PropTypes.string,
    setSaveOpen: PropTypes.func
};
