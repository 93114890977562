import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';
import logo from '../../images/logo.svg';
import toons from '../../images/stanGang.svg';
import okta from '../../images/btn_marvel_okta.svg';
import oktaHover from '../../images/btn_marvel_okta_hover.svg';
import bg1 from '../../images/login_bg-1.png';
import bg2 from '../../images/login_bg-2.png';
import bg3 from '../../images/login_bg-3.png';
import bg4 from '../../images/login_bg-4.png';
import bg5 from '../../images/login_bg-5.png';
import bg6 from '../../images/login_bg-6.png';
import signIn from '../../images/signinicon.svg';

export const LoginContext = React.createContext();
const fadeOut = keyframes`
100% {
opacity: 0;
}
`;

const flickerImages = keyframes`
0% {
background-image: url("${bg2}");
}
20% {
background-image: url("${bg3}");
}
40% {
background-image: url("${bg4}");
}
60% {
background-image: url("${bg5}");
}
80% {
background-image: url("${bg6}");
}
100% {
background-image: url("${bg1}");
}
`;
const AnimatedBg = styled.div`
backgroundImage: url("${bg1}");
animation: ${flickerImages} 750ms 2 steps(1, end), ${fadeOut} 2250ms forwards;
`;

const Bg = styled.div`
backgroundImage: url("${bg1}");
`;

const Login = () => {
    const [init, setInit] = useState(true);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setInit(false);
        }, 2250);
    }, []);

    return (
        <LoginContext.Provider value={{}}>
            <div className='Login'>
                <Helmet><title>Marvel Comics Login Page</title></Helmet>
                <div className='Login__bg' />
                {init ? <AnimatedBg className='images' /> : <Bg className='images' />}
                <div className='Login__Modal flex column align-center'>
                    <img src={logo} />
                    <div>
                        <img src={toons} className='Login__Modal__toons' />
                        <div className='relative'>
                            <img src={signIn} className='Login__SignIn__Icon' />
                        </div>
                        <div
                            className='Login__MyID pointer'
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <a href='/auth/login'>
                                <img className='Login__MyID__image' src={hover ? oktaHover : okta} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </LoginContext.Provider>
    );
};

export default Login;
